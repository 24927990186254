import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import MapIcon from '@mui/icons-material/Map';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import InfoIcon from '@mui/icons-material/Info';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { FormControlLabel, Typography, RadioGroup, FormControl, Radio, Slider, Modal, Button } from '@mui/material';

import API_BASE_URL from '../../apiConfig';
import MapDirections from '../Map/MapDirections';
import MenuCard from './MenuCard';


const RestaurantDetails = () => {
  const [restaurant, setRestaurant] = useState({});
  const [imageUrl, setImageUrl] = useState("");
  const [cuisines, setCuisines] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [priceRange, setPriceRange] = useState([0, 10000]);
  const [maxPrice, setMaxPrice] = useState();
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const { id } = useParams();

  //for map
  const [isMapOpen, setIsMapOpen] = useState(false);

  const handleOpenMap = () => {
    setIsMapOpen(true);
  };

  const handleCloseMap = () => {
    setIsMapOpen(false);
  };

  //
  useEffect(() => {
    const fetchRestaurantDetails = async () => {
      // const token = localStorage.getItem('token');

      try {
        const response = await fetch(`${API_BASE_URL}/restaurant/allRestaurants`, {
          method: 'GET',
          headers: {
            // 'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          const restaurantDetails = data.find((item) => item.id === parseInt(id));

          if (restaurantDetails) {
            setRestaurant(restaurantDetails);

            const imageResponse = await fetch(`${API_BASE_URL}/restaurant/downloadImage/${id}`, {
              method: 'GET',
              headers: {
                // 'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            });

            if (imageResponse.ok) {
              const imageBlob = await imageResponse.blob();
              const imageObjectURL = URL.createObjectURL(imageBlob);
              setImageUrl(imageObjectURL);
            }
            
            const cuisinesResponse = await fetch(`${API_BASE_URL}/cuisine/allCuisines/${id}`, {
              method: 'GET',
              headers: {
                // 'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            });

            if (cuisinesResponse.ok) {
             
              const cuisinesData = await cuisinesResponse.json();
              setCuisines(cuisinesData);

              const uniqueCategories = [...new Set(cuisinesData.map(cuisine => cuisine.category))];
              setCategories(uniqueCategories);

              const maxPriceValue = Math.max(...cuisinesData.map(cuisine => cuisine.price));
              setMaxPrice(maxPriceValue);
            }
          }
        } else {
          console.error('Failed to fetch restaurant details');
        }
      } catch (error) {
        console.error('Error fetching restaurant details:', error);
      }
    };

    fetchRestaurantDetails();
  }, [id]);

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handlePriceChange = (event, newValue) => {
    setPriceRange(newValue);
  };

  const filteredCuisines = cuisines.filter(cuisine =>
    (selectedCategory === '' || cuisine.category === selectedCategory) &&
    cuisine.price >= priceRange[0] && cuisine.price <= priceRange[1] &&
    (
      cuisine.cuisineName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      cuisine.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
      cuisine.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      cuisine.price.toString().includes(searchTerm)
    )
  );

  const toggleDropdown = () => setDropdownOpen(!isDropdownOpen);


  return (
    <div className="px-4 lg:px-16  min-h-screen pb-20 pt-24 bg-green"> 
      {/* Restaurant Information Section */}
      <section className="mt-4 flex flex-col lg:flex-row items-center lg:items-start ">
        <div className="w-full lg:w-1/2 flex justify-center lg:justify-start lg:pr-6">
          <div className="w-full max-w-md lg:max-w-full lg:h-[300px]"> {/* Reduced height */}
            <img
              className="w-full h-[250px] sm:h-[300px] lg:h-[300px] object-cover rounded-xl shadow-lg"
              src={imageUrl}
              alt="Restaurant"
            />
          </div>
        </div>
        <div className="w-full lg:w-1/2 mt-6 lg:mt-0 text-left">
          <h1 className="text-gray-800 font-extrabold text-2xl sm:text-3xl lg:text-4xl drop-shadow-md mb-4">
            {restaurant.name || "Restaurant Name"}
          </h1>
          <div className="space-y-4">
            <p className="text-gray-600 flex items-center gap-3">
              <RestaurantMenuIcon /> <span>{restaurant.cuisineType || "Cuisine Type"}</span>
            </p>
            <p className="text-gray-600 flex items-center gap-3">
              <CalendarTodayIcon /> <span>{restaurant.operatingHours || "Operating Hours"}</span>
            </p>
            <div className="text-gray-600 flex items-start gap-3 max-w-full">
              <InfoIcon />
              <span className="whitespace-normal break-words max-w-full">
                {restaurant.description || "Restaurant description goes here."}
              </span>
            </div>

            <p className="text-gray-600 flex items-center gap-3">
              <LocationOnIcon /> <span>
                {restaurant.addressLine1 || "Address Line 1"}, {restaurant.city || "City"}, {restaurant.state || "State"}, {restaurant.country || "Country"}
              </span>
            </p>

            <p className="text-gray-600 flex items-center gap-3">
              <MapIcon/><span>{restaurant.placeName}</span>
            </p>

            {/* Button to View on Map */}
            <Button variant="contained" color="primary" onClick={handleOpenMap}>
              View on Map
            </Button>

            {/* Map Directions Modal */}
            <Modal open={isMapOpen} onClose={handleCloseMap}>
                <div
                  className="w-full max-w-3xl mx-auto my-8 p-2 bg-gradient-to-r from-blue-50 to-white rounded-lg shadow-xl relative overflow-hidden"
                  style={{ maxHeight: '90vh' }}
                >
                  {/* Close icon at the top-right corner */}
                  <button
                    onClick={handleCloseMap}
                    className="absolute top-2 right-2 text-gray-700 hover:text-red-600 text-2xl font-bold px-2 py-1 rounded-full hover:bg-gray-200 transition duration-150 ease-in-out"
                  >
                    &times;
                  </button>
                  
                  <h3 className="text-xl font-semibold mb-2 text-center text-blue-800">
                    Directions to {restaurant.name}
                  </h3>
                  {/* Map Container */}
                  <div className="w-full h-[80vh] rounded-lg overflow-hidden">
                    <MapDirections restaurantId={restaurant.id} />
                  </div>
                </div>
            </Modal>

          </div>

        </div>
      </section>

      {/* Search and Cuisine Types Section */}
      <div className="bg-white z-10 flex flex-col gap-2 mt-2 pt-4 pb-1">
        <div className="flex items-center gap-4">
          {/* Search Menu */}
          <input
            type="text"
            placeholder="Search menu items..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full sm:w-60 rounded-full px-3 py-2 bg-white/50 backdrop-blur-md border border-gray-300 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500"
          />

          {/* Cuisine Types */}
          <div className="flex gap-2 overflow-x-auto px-2 -mx-2 py-1 ml-4 ">
            {categories.map((category) => (
              <button
                key={category}
                onClick={() => setSelectedCategory(category)}
                className={`flex-shrink-0 px-3 py-2 text-sm font-semibold 
                  ${selectedCategory === category ? 'bg-black text-white' : 'text-gray-700'} 
                  hover:bg-gray-100 hover:text-black transition-all`}
                style={{
                  scrollSnapAlign: 'start',
                  maxWidth: '150px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {category} ({cuisines.filter(cuisine => cuisine.category === category).length})
              </button>
            ))}
          </div>
        </div>
      </div>

      
        {/* Display Filtered Cuisine Count */}
        <div className="text-gray-600 text-sm mt-1">
          Showing {filteredCuisines.length} items for "{selectedCategory || 'All Categories'}"
        </div>

      {/* Filter and Menu Section */}
      <section className="pt-4 flex flex-col lg:flex-row gap-8">
        <div className="w-full lg:w-1/4 lg:sticky lg:top-28 mb-8 lg:mb-0 space-y-6">
          <div className="bg-white shadow-lg rounded-lg p-4 md:p-6 mt-2">
            <Typography variant="h5" className="pb-4 font-semibold text-gray-800">
              Price Range
            </Typography>
            <Slider
              value={priceRange}
              onChange={handlePriceChange}
              valueLabelDisplay="auto"
              min={0}
              max={maxPrice}
              step={1}
              aria-labelledby="price-range-slider"
            />
          </div>
          <div className="bg-white shadow-lg rounded-lg p-4 md:p-6">
            <Typography variant="h5" className="pb-4 font-semibold text-gray-800">
              Category
            </Typography>
            <div className="relative">
              {/* Dropdown Toggle */}
              <button
                onClick={toggleDropdown}
                className="w-full bg-gray-200 text-gray-700 py-2 px-4 rounded-md text-left"
              >
                {selectedCategory || "Select Category"}
                <span className={`float-right transform ${isDropdownOpen ? 'rotate-180' : ''}`}>
                  ⮟
                </span>
              </button>

              {/* Dropdown Content */}
              {isDropdownOpen && (
                <div className="absolute w-full bg-white shadow-lg rounded-lg mt-2 z-10 p-4">
                  <FormControl component="fieldset">
                    <RadioGroup
                      onChange={(e) => {
                        handleCategoryChange(e);
                        toggleDropdown(); // Close dropdown after selection
                      }}
                      name="food_type"
                      value={selectedCategory}
                    >
                      <FormControlLabel value="" control={<Radio />} label="All" />
                      {categories.map((category) => (
                        <FormControlLabel
                          key={category}
                          value={category}
                          control={<Radio />}
                          label={category.length > 18 ? `${category.slice(0, 18)}...` : category}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </div>
              )}
            </div>
          </div>
        </div>
        

      {/* Menu Items Section */}
      <div className="w-full lg:w-3/4 flex flex-col bg-none">
        {filteredCuisines.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            {filteredCuisines.map((cuisine) => (
              <MenuCard 
                key={cuisine.id}
                {...cuisine} 
              />
            ))}
          </div>
        ) : (
          <div className="text-center text-gray-600 text-lg font-semibold mt-10">
            No menu is currently available. Please check back with us soon. Thank you!
          </div>
        )}
      </div>



      </section>

    </div>

  );
};

export default RestaurantDetails;
