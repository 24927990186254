import React, { useState, useEffect } from 'react';
import CartItem from './CartItem';
import API_BASE_URL from '../../apiConfig';
import { useNavigate } from 'react-router-dom';

const CartPage = () => {
  const [cartItems, setCartItems] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showRedirectModal, setShowRedirectModal] = useState(false);
  const navigate = useNavigate();
  const userId = JSON.parse(localStorage.getItem('userId'));

  useEffect(() => {
    const savedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    setCartItems(savedCartItems);
  }, []);

  const handleAddQuantity = (cuisineId) => {
    const updatedItems = cartItems.map((item) =>
      item.cuisineId === cuisineId ? { ...item, quantity: item.quantity + 1 } : item
    );
    setCartItems(updatedItems);
    updateLocalStorage(updatedItems);
  };

  const handleRemoveQuantity = (cuisineId) => {
    const updatedItems = cartItems
      .map((item) =>
        item.cuisineId === cuisineId ? { ...item, quantity: item.quantity - 1 } : item
      )
      .filter((item) => item.quantity > 0); // Remove items with quantity 0

    setCartItems(updatedItems);
    updateLocalStorage(updatedItems);

    if (updatedItems.length !== cartItems.length) {
      window.location.reload(); // Trigger a page refresh when quantity reaches 0
    }
  };

  const updateLocalStorage = (updatedItems) => {
    if (updatedItems.length === 0) {
      localStorage.removeItem('cartItems');
    } else {
      localStorage.setItem('cartItems', JSON.stringify(updatedItems));
    }
  };

  const totalPrice = cartItems.reduce((total, item) => total + item.cuisinePrice * item.quantity, 0);
  // const deliveryFee = 5;
  // const platformFee = 2;
  // const restaurantFee = 3;
  const totalCost = totalPrice; //+ deliveryFee + platformFee + restaurantFee;

  const handleOrderNow = () => {
    setShowConfirmationModal(true);
  };

  const confirmOrder = async () => {
    const token = localStorage.getItem('token');

    setIsLoading(true);

    try {
      const requests = cartItems.map(async (item) => {
        const order = {
          customerId: parseInt(userId),
          restaurantId: parseInt(item.restaurantId),
          restaurantName: item.restaurantName, 
          cuisineName: item.cuisineName,  
          cuisineId: parseInt(item.cuisineId),  
          cuisinePrice: item.cuisinePrice,  
          quantity: parseInt(item.quantity),  
          pickupTime: '60 min',
          status: 'Pending',
        };

        const response = await fetch(`${API_BASE_URL}/order/createOrder`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(order),
        });

        if (!response.ok) {
          throw new Error('Failed to create order for item ' + item.cuisineId);
        }

        return response.json();
      });

      await Promise.all(requests);
      setShowConfirmationModal(false);
      setShowRedirectModal(true);
      localStorage.removeItem('cartItems');
      setCartItems([]);

    } catch (error) {
      console.error('Error creating orders:', error);
      alert('Failed to create one or more orders. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (showRedirectModal) {
      const timeout = setTimeout(() => {
        navigate(`/my-restoura/orders-history/${userId}`);
        setTimeout(() => {
          window.location.reload(); // Trigger page reload after navigation
        }, 100); // Add a small delay to ensure navigation completes
      }, 3000);
      
      return () => clearTimeout(timeout);
    }
  }, [showRedirectModal, navigate, userId]);
  



  return (
    <div className="p-6 bg-gradient-to-br from-gray-50 to-gray-200 min-h-screen mt-20">
      <div className="max-w-5xl mx-auto bg-white shadow-2xl rounded-2xl overflow-hidden">
        <img
          src="https://cdn.pixabay.com/photo/2024/06/12/07/02/shopping-bag-8824561_640.jpg"
          alt="Banner"
          className="w-full h-56 object-cover rounded-t-2xl"
        />
        <div className="p-8">
          {cartItems.length === 0 ? (
            <div className="text-center py-16">
              <h2 className="text-3xl font-extrabold text-gray-700 mb-4">Your Cart is Empty!</h2>
              <p className="text-gray-500 mb-6">Would you like to order something?</p>
              <a href="/restaurants" className="text-pink-600 font-semibold underline hover:text-pink-800 transition duration-300">
                Browse Restaurants
              </a>
            </div>
          ) : (
            <div className="flex flex-col md:flex-row gap-8">
              {/* Order List Section */}
              <div className="w-full md:w-2/3">
                <h2 className="text-2xl font-semibold mb-6 text-gray-800">Order List</h2>
                <div className="space-y-6">
                  {cartItems.map((item) => (
                    <CartItem
                      key={item.cuisineId}
                      item={item}
                      onAdd={() => handleAddQuantity(item.cuisineId)}
                      onRemove={() => handleRemoveQuantity(item.cuisineId)}
                    />
                  ))}
                </div>
              </div>
  
              {/* Billing Details Section */}
              <div className="w-full md:w-1/3 bg-gray-50 p-8 rounded-xl shadow-lg">
                <h2 className="text-2xl font-semibold mb-6 text-center text-gray-800">Billing Details</h2>
                <div className="space-y-4 text-right">
                  <div className="flex justify-between text-lg text-gray-600">
                    <span className="font-medium">Subtotal:</span>
                    <span>${totalPrice.toFixed(2)}</span>
                  </div>
                  <div className="flex justify-between text-2xl font-bold text-gray-800">
                    <span>Total:</span>
                    <span>${totalCost.toFixed(2)}</span>
                  </div>
                  <button
                    className={`w-full mt-6 py-3 px-6 rounded-full text-white shadow-lg font-medium transition ${
                      cartItems.length === 0
                        ? 'bg-gray-300 cursor-not-allowed'
                        : 'bg-pink-600 hover:bg-pink-700 transition duration-300 ease-in-out transform hover:scale-105'
                    }`}
                    onClick={handleOrderNow}
                    disabled={cartItems.length === 0 || isLoading}
                  >
                    {isLoading ? 'Placing Orders...' : 'Order Now'}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
  
      {/* Confirmation Modal */}
      {showConfirmationModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center animate-fadeIn">
          <div className="bg-white p-10 rounded-lg shadow-2xl text-center">
            <h2 className="text-2xl font-bold mb-4 text-gray-700">Confirm Your Order</h2>
            <p className="text-gray-500 mb-8">Are you sure you want to place this order?</p>
            <div className="flex justify-center space-x-6">
              <button
                className="px-6 py-2 rounded-full bg-gray-300 text-gray-700 hover:bg-gray-400 transition"
                onClick={() => setShowConfirmationModal(false)}
              >
                Cancel
              </button>
              <button
                className="px-6 py-2 rounded-full bg-pink-600 text-white hover:bg-pink-700 transition"
                onClick={confirmOrder}
              >
                Confirm Order
              </button>
            </div>
          </div>
        </div>
      )}
  
      {/* Redirect Modal */}
      {showRedirectModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center animate-fadeIn">
          <div className="bg-white p-10 rounded-3xl shadow-2xl text-center">
            <p className="text-sm font-semibold uppercase tracking-widest text-pink-600">
              Your order is on the way
            </p>
            <h2 className="mt-6 text-xl font-bold text-gray-700">
              Thanks for your purchase!
            </h2>
            <p className="mt-4 text-gray-500">Let’s track your orders!</p>
            <p className="mt-4 text-gray-500">Please pay on Pickup 😊</p>
            <a
              className="mt-8 inline-block w-full rounded-full bg-pink-600 py-4 text-sm font-bold text-white shadow-xl hover:bg-pink-700 transition"
              href={`/my-restoura/orders-history/${userId}`}
            >
              Track Order
            </a>
          </div>
        </div>
      )}
    </div>
  );
  

  
};

export default CartPage;
