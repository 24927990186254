import React from 'react';

const AboutUs = () => {
  return (
    <div className="bg-gray-50 mt-20">
      {/* Hero Section */}
      <section className="bg-gradient-to-b from-white to-gray-100">
        <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8 text-center">
          <h2 className="text-5xl font-extrabold text-gray-900 sm:text-6xl leading-tight">
            Welcome to <span className="text-rose-600">Restoura</span>
          </h2>
          <p className="mt-6 text-xl text-gray-600 max-w-3xl mx-auto">
            At Restoura, we are committed to connecting local chefs with food lovers seeking authentic homemade dishes. With a focus on quality, taste, and community, we bring the flavors of home to your table.
          </p>
        </div>
      </section>

      {/* Vision & Mission Section */}
      <section className="py-16 bg-white">
        <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-4xl font-bold text-gray-900">Our Vision & Mission</h2>
            <p className="mt-4 text-lg text-gray-600 max-w-3xl mx-auto">
              Our vision is to revolutionize the homemade food industry by empowering local chefs and providing customers with a reliable platform for unique and delicious meals. We strive to ensure every meal is made with care and authenticity, reflecting the chef's love for their craft.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="bg-rose-50 p-8 rounded-lg shadow-lg text-center">
              <h3 className="text-2xl font-semibold text-rose-600">Vision</h3>
              <p className="mt-4 text-gray-700">
                To be the go-to platform for homemade meals, bridging cultures and communities through food.
              </p>
            </div>
            <div className="bg-blue-50 p-8 rounded-lg shadow-lg text-center">
              <h3 className="text-2xl font-semibold text-blue-600">Mission</h3>
              <p className="mt-4 text-gray-700">
                We strive to empower chefs to grow their culinary business while providing customers with the freshest, most authentic dishes from around the world.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Feature Section */}
      <section className="bg-gray-100 py-16">
        <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-gray-900 text-center">Why Choose Restoura?</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-8">
            {[
              {
                color: "bg-blue-500",
                title: "Authentic Native Experience",
                description: "Enjoy a variety of native dishes, carefully crafted by local chefs, to bring the flavors of home.",
                iconPath: "M20 12H4"
              },
              {
                color: "bg-green-500",
                title: "Halal-Friendly Meals",
                description: "We strive to ensure Halal standards, offering you peace of mind with every bite.",
                iconPath: "M16 9v10m4-6H8m0-10v10m4-6H4"
              },
              {
                color: "bg-yellow-500",
                title: "Support Local Chefs",
                description: "Every order helps our chefs grow their business and showcase their passion for homemade food.",
                iconPath: "M12 20l4-16m0 0l-4-4-4 4m8 16l-4-4-4 4"
              },
            ].map((feature, index) => (
              <div key={index} className="p-8 bg-white rounded-lg shadow-lg text-center hover:shadow-xl transition duration-300">
                <div className={`h-16 w-16 flex items-center justify-center rounded-full ${feature.color} text-white mx-auto`}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={feature.iconPath} />
                  </svg>
                </div>
                <h3 className="mt-6 text-xl font-semibold text-gray-900">{feature.title}</h3>
                <p className="mt-4 text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Customer Testimonials Section */}
      {/* <section className="bg-gray-50 py-16">
        <div className="text-center">
          <h2 className="text-4xl font-bold text-gray-900">What Our Customers Say</h2>
          <p className="mt-4 text-lg text-gray-600">Hear from our happy customers and chefs</p>
        </div>
        <div className="mx-auto mt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-screen-xl px-4">
          {[
            {
              text: "Restoura allows me to indulge in the taste of home, even from across the world. I can't recommend it enough!",
              name: "Jane Doe",
              role: "Customer",
              imgSrc: "https://randomuser.me/api/portraits/women/32.jpg"
            },
            {
              text: "As a chef, Restoura has been instrumental in allowing me to grow my food business. The platform is fantastic!",
              name: "John Smith",
              role: "Chef",
              imgSrc: "https://randomuser.me/api/portraits/men/32.jpg"
            },
          ].map((testimonial, index) => (
            <article key={index} className="bg-white p-8 rounded-lg shadow-lg text-center transition hover:shadow-xl">
              <p className="text-gray-600">{testimonial.text}</p>
              <footer className="mt-6 flex flex-col items-center">
                <img src={testimonial.imgSrc} alt={testimonial.name} className="h-12 w-12 rounded-full object-cover" />
                <div className="text-sm mt-3">
                  <p className="text-gray-900 font-medium">{testimonial.name}</p>
                  <p className="text-gray-500">{testimonial.role}</p>
                </div>
              </footer>
            </article>
          ))}
        </div>
      </section> */}

      {/* Meet Our Chefs Section */}
      {/* <section className="bg-white py-16">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold text-gray-900">Meet Our Chefs</h2>
          <p className="text-lg text-gray-600">The culinary artists behind every dish</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-screen-xl mx-auto px-4">
          {[
            {
              imgSrc: "https://images.unsplash.com/photo-1588515724527-074a7a56616c?auto=format&fit=crop&w=1500&q=80",
              name: "Sarah Lee",
              specialization: "Halal / Authentic Cuisine"
            },
            {
              imgSrc: "https://images.unsplash.com/photo-1661956602116-aa6865609028?auto=format&fit=crop&w=764&q=80",
              name: "Mike Chang",
              specialization: "Asian Fusion"
            },
          ].map((chef, index) => (
            <a key={index} href="/null" className="group relative block overflow-hidden rounded-lg shadow-lg">
              <img src={chef.imgSrc} alt={chef.name} className="object-cover w-full h-64 transition duration-500 group-hover:opacity-80" />
              <div className="absolute inset-0 bg-gradient-to-t from-gray-900/60 to-transparent flex items-end p-4">
                <div className="text-white">
                  <p className="text-lg font-medium">{chef.name}</p>
                  <p className="text-sm">{chef.specialization}</p>
                </div>
              </div>
            </a>
          ))}
        </div>
      </section> */}

      {/* Call to Action Section */}
      <section className="py-16 bg-gradient-to-r from-rose-500 to-pink-500 text-white">
        <div className="max-w-screen-xl mx-auto text-center">
          <h2 className="text-4xl font-bold">Ready to Savor Authentic Homemade Meals?</h2>
          <p className="mt-4 text-lg">
            Join our community of food lovers and enjoy fresh, authentic meals prepared by talented chefs near you.
          </p>
          <a href="/register" className="inline-block mt-6 px-8 py-3 bg-white text-rose-500 rounded-lg font-semibold hover:bg-gray-50 transition">
            Get Started Today
          </a>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
