import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, StandaloneSearchBox, useLoadScript, Marker } from '@react-google-maps/api';

const libraries = ['places'];


const MapComponent = ({ onLocationSelect }) => {
  const [currentPosition, setCurrentPosition] = useState(null);
  const [placeName, setPlaceName] = useState("");
  const searchBoxRef = useRef(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: libraries,
  });

  useEffect(() => {
    if (navigator.geolocation && isLoaded) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const location = { lat: latitude, lng: longitude };
          setCurrentPosition(location);
          fetchPlaceName(location);
        },
        () => console.error("Geolocation permission denied.")
      );
    }
  }, [isLoaded]);

  const fetchPlaceName = async (position) => {
    if (!window.google) {
      console.error("Google Maps API not loaded.");
      return;
    }

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: position }, (results, status) => {
      if (status === "OK" && results[0]) {
        const place = results[0].formatted_address;
        setPlaceName(place);
        localStorage.setItem("placeName", place);
        localStorage.setItem("latitude", position.lat);
        localStorage.setItem("longitude", position.lng);
        console.log(`Latitude: ${position.lat}, Longitude: ${position.lng}, Place: ${place}`);
      } else {
        console.error("Geocoder failed due to: " + status);
      }
    });
  };

  const handleSearchChange = (place) => {
    const newLocation = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };
    setCurrentPosition(newLocation);
    setPlaceName(place.formatted_address);
    onLocationSelect({ placeName: place.formatted_address, ...newLocation });
    localStorage.setItem("placeName", place.formatted_address);
    localStorage.setItem("latitude", newLocation.lat);
    localStorage.setItem("longitude", newLocation.lng);
    console.log(`Latitude: ${newLocation.lat}, Longitude: ${newLocation.lng}, Place: ${place.formatted_address}`);
  };

  useEffect(() => {
    if (isLoaded && searchBoxRef.current) {
      const searchBox = new window.google.maps.places.SearchBox(searchBoxRef.current);
      searchBox.addListener("places_changed", () => {
        const places = searchBox.getPlaces();
        if (places && places.length > 0) {
          handleSearchChange(places[0]);
        }
      });
    }
  }, [isLoaded]);

  const handleMapClick = (e) => {
    const newPosition = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    setCurrentPosition(newPosition);
    fetchPlaceName(newPosition);
  };

  if (!isLoaded || !currentPosition) return <p>Loading...</p>;

  return (
    <div className="map-container" style={{ position: "relative", width: "100%", height: "400px" }}>
      <StandaloneSearchBox
        onLoad={(ref) => (searchBoxRef.current = ref)}
        onPlacesChanged={() => {
          const places = searchBoxRef.current.getPlaces();
          if (places && places.length > 0) {
            handleSearchChange(places[0]);
          }
        }}
      >
        <input
          type="text"
          placeholder="Search for a place..."
          style={{
            width: "300px",
            padding: "10px",
            position: "absolute",
            top: "10px",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: "10",
            borderRadius: "4px",
            border: "1px solid #ccc",
          }}
          value={placeName}
          onChange={(e) => setPlaceName(e.target.value)}
        />
      </StandaloneSearchBox>
      <GoogleMap
        center={currentPosition}
        zoom={15}
        mapContainerStyle={{ width: "100%", height: "100%" }}
        onClick={handleMapClick}
        options={{
          fullscreenControl: false, // Disable fullscreen control
        }}
      >
        {/* Marker for the current position */}
        {currentPosition && (
          <Marker
            position={currentPosition}
            label="You" // Optional label for the marker
          />
        )}
      </GoogleMap>
    </div>
  );
};

export default MapComponent;
