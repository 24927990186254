import React, { useState, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from '../../apiConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faCheckCircle, faTimesCircle  } from '@fortawesome/free-solid-svg-icons';


const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState(new Array(6).fill(''));
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState('');
  const [passwordLengthError, setPasswordLengthError] = useState('');
  const navigate = useNavigate();

  const inputRefs = useRef(Array(6).fill().map(() => React.createRef()));
  
  const MIN_PASSWORD_LENGTH = 8;
  // Send verification code
  const handleSendVerificationCode = async () => {
    setLoading(true);
    try {
        const response = await fetch(`${API_BASE_URL}/auth/forgetPassword?email=${email}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        });

        if (response.ok) {
            setIsCodeSent(true);
            setErrorMessage('');
            setSuccessMessage('Verification code sent to your email. It will expire in 5 minutes.');
        } else {
            // Check if response is JSON; otherwise, handle as text
            const errorData = await response.clone().json().catch(() => response.text());
            const errorMessage = typeof errorData === 'string' ? errorData : errorData.message;
            setErrorMessage(errorMessage || 'Failed to send verification code.');
        }
    } catch (error) {
        setErrorMessage('An error occurred while sending the verification code.');
    } finally {
        setLoading(false);
    }
  };
  
  // Verify code
  const handleVerification = async () => {
    const code = verificationCode.join('');
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/auth/verify`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, verificationCode: code }),
      });
      if (response.ok) {
        setIsVerified(true);
        setErrorMessage('');
        setSuccessMessage('Verification successful! Please enter your new password.');
      } else {
        setErrorMessage('The verification code does not match.');
        setSuccessMessage('');
      }
    } catch (error) {
      setErrorMessage('An error occurred during verification.');
    } finally {
      setLoading(false);
    }
  };

  // Reset password
  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setErrorMessage(''); // Reset error message before validation
    setPasswordMatchError('');
    setPasswordLengthError('');

    // Check if new password fields are filled and valid
    if (newPassword === '' || confirmPassword === '') {
        setErrorMessage('Please fill in all fields.');
        return;
    }

    if (newPassword.length < MIN_PASSWORD_LENGTH) {
        setPasswordLengthError(`Password must be at least ${MIN_PASSWORD_LENGTH} characters long.`);
        return;
    }

    if (newPassword !== confirmPassword) {
        setPasswordMatchError('Passwords do not match.');
        return;
    }

    setLoading(true);
    try {
        const response = await fetch(`${API_BASE_URL}/auth/changePassword`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password: newPassword }),
        });
        if (response.ok) {
            setSuccessMessage('Password has been successfully changed.');
            setTimeout(() => navigate('/login'), 3000);
        } else {
            const errorData = await response.json();
            setErrorMessage(errorData.message || 'Password reset failed.');
        }
    } catch (error) {
        setErrorMessage('An error occurred while resetting the password.');
    } finally {
        setLoading(false);
    }
  };

  // Resend verification code
  const handleResendCode = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/auth/resend?email=${email}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      });
  
      if (response.ok) {
        setErrorMessage('');
        setSuccessMessage('Verification code resent to your email.');
      } else {
        const contentType = response.headers.get("content-type");
        let errorMessage;
  
        if (contentType && contentType.includes("application/json")) {
          const errorData = await response.json();
          errorMessage = errorData.message || 'Failed to resend the code. Please try again.';
        } else {
          // If the content type is not JSON, read the response as text
          errorMessage = await response.text();
        }
  
        setErrorMessage(errorMessage || 'Failed to resend the code. Please try again.');
      }
    } catch (error) {
      
      setErrorMessage('An error occurred while resending the code. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Handle verification code input change
  const handleChange = (value, index) => {
    if (/^[0-9]?$/.test(value)) {
      const newCode = [...verificationCode];
      newCode[index] = value;
      setVerificationCode(newCode);

      if (value && index < verificationCode.length - 1) {
        inputRefs.current[index + 1].current.focus();
      }
    }
  };
  
  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  return (
    <div className="flex flex-col items-center justify-center bg-gray-100 px-4 sm:px-0 py-12 mt-20">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-md sm:max-w-lg">
        {!isCodeSent ? (
          <>
            <h2 className="text-2xl font-bold text-center mb-6 text-gray-800">Check Your Email</h2>
            <p className="text-center mb-4 text-gray-600">Enter your email below to check if an account exists. If it does, we will send you a verification code.</p>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-3 mb-4 border border-gray-300 rounded-md"
            />
            {errorMessage && <p className="text-red-500 text-sm text-center mt-4">{errorMessage}</p>}
            {successMessage && <p className="text-green-500 text-sm text-center mt-4">{successMessage}</p>}
            <button
              onClick={handleSendVerificationCode}
              disabled={loading}
              className="w-full py-3 px-4 font-bold text-white bg-blue-500 hover:bg-blue-600 rounded-md transition duration-300 mt-6"
            >
              {loading ? 'Sending...' : 'Send Verification Code'}
            </button>
          </>
        ) : !isVerified ? (
          <>
            <h2 className="text-2xl font-bold text-center mb-6 text-gray-800">Verify Code</h2>
            <div className="flex justify-center space-x-2 mt-2">
              {verificationCode.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  value={digit}
                  onChange={(e) => handleChange(e.target.value, index)}
                  ref={inputRefs.current[index]}
                  className="w-12 h-12 text-center text-xl font-semibold border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              ))}
            </div>
            {errorMessage && <p className="text-red-500 text-sm text-center mt-4">{errorMessage}</p>}
            {successMessage && <p className="text-green-500 text-sm text-center mt-4">{successMessage}</p>}
            <button
              onClick={handleVerification}
              disabled={loading}
              className="w-full py-3 px-4 font-bold text-white bg-blue-500 hover:bg-blue-600 rounded-md transition duration-300 mt-6"
            >
              {loading ? 'Verifying...' : 'Verify'}
            </button>
            <span className="mt-4 flex justify-center items-center ">
              Didn’t receive a code? 
              <button onClick={handleResendCode} className="text-blue-500 underline ml-1">
                Resend Code
              </button>
            </span>

          </>
        ) : (
          <>
              {/* Password reset form */}
              <h2 className="text-2xl font-bold text-center mb-6 text-gray-800">Reset Password</h2>
              
              <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">New Password</label>
                  <div className="relative">
                      <input
                          id="newPassword"
                          type={passwordVisible ? 'text' : 'password'}
                          className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          required
                      />
                      <span
                          className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer"
                          onClick={togglePasswordVisibility}
                      >
                          <FontAwesomeIcon icon={passwordVisible ? faEye : faEyeSlash} />
                      </span>
                  </div>
                  {newPassword.length > 0 && (
                      <p className={`text-sm mt-1 ${newPassword.length >= MIN_PASSWORD_LENGTH ? 'text-green-600' : 'text-red-500'}`}>
                          {newPassword.length >= MIN_PASSWORD_LENGTH ? (
                              <>
                                  <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />
                                  Password meets the length requirement.
                              </>
                          ) : (
                              <>
                                  <FontAwesomeIcon icon={faTimesCircle} className="mr-1" />
                                  Password must be at least {MIN_PASSWORD_LENGTH} characters long.
                              </>
                          )}
                      </p>
                  )}
                  {passwordLengthError && (
                      <p className="text-red-500 text-sm">{passwordLengthError}</p>
                  )}
              </div>

            <div className="space-y-1">
                <label className="block text-sm font-medium text-gray-700">Confirm New Password</label>
                <div className="relative">
                    <input
                        id="confirmPassword"
                        type={confirmPasswordVisible ? 'text' : 'password'}
                        className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                    <span
                        className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer"
                        onClick={toggleConfirmPasswordVisibility}
                    >
                        <FontAwesomeIcon icon={confirmPasswordVisible ? faEye : faEyeSlash} />
                    </span>
                </div>
                {passwordMatchError && (
                    <p className="text-red-500 text-sm">{passwordMatchError}</p>
                )}
            </div>

              <button
                onClick={handlePasswordReset}
                disabled={loading}
                className="w-full py-3 px-4 font-bold text-white bg-blue-500 hover:bg-blue-600 rounded-md transition duration-300 mt-6"
              >
                {loading ? 'Resetting...' : 'Reset Password'}
              </button>      </>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
