import React, { useState, useEffect } from 'react';
import API_BASE_URL from '../../apiConfig';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CartItem = ({ item, onAdd, onRemove }) => {
  const [imageUrl, setImageUrl] = useState('');



  useEffect(() => {
    const fetchCuisineImage = async () => {
      const token = localStorage.getItem('token');
      
      try {
        const response = await fetch(`${API_BASE_URL}/cuisine/downloadImage/${item.cuisineId}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const imageBlob = await response.blob();
          const imageObjectUrl = URL.createObjectURL(imageBlob);
          setImageUrl(imageObjectUrl); // Set the image URL in state
        } else {
          console.error('Failed to fetch image');
        }
      } catch (error) {
        console.error('Error fetching cuisine image:', error);
      }
    };

    fetchCuisineImage();
  }, [item.cuisineId]);

  return (
    <div className="flex items-center justify-between p-4 bg-white rounded-lg shadow-lg transform transition duration-200 hover:scale-105 hover:shadow-xl">
      <div className="flex items-center space-x-4">
        {imageUrl ? (
          <img src={imageUrl} alt={item.cuisineName} className="w-20 h-20 object-cover rounded-lg border-2 border-gray-200" />
        ) : (
          <div className="w-20 h-20 bg-gray-300 rounded-lg"></div>
        )}
        <div>
          <h2 className="text-xl font-semibold text-gray-800">{item.cuisineName}</h2>
          <p className="text-gray-500">Price: <span className="text-blue-600 font-medium">${item.cuisinePrice}</span></p>
          <div className="flex items-center space-x-2">
            <p className="text-gray-500">Quantity:</p>
            <span className="px-2 py-1 bg-blue-100 text-blue-700 font-semibold rounded-full">{item.quantity}</span>
          </div>
        </div>
      </div>
      <div className="flex space-x-2">
        <button
          onClick={() => onAdd(item.cuisineId)}
          className="w-10 h-10 bg-green-500 text-white rounded-full flex items-center justify-center shadow transition duration-200 transform hover:bg-green-600 hover:scale-105"
        >
          +
        </button>
        {item.quantity === 1 ? (
          <button
            onClick={() => onRemove(item.cuisineId)}
            className="w-10 h-10 bg-red-500 text-white rounded-full flex items-center justify-center shadow transition duration-200 transform hover:bg-red-600 hover:scale-105"
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </button>
        ) : (
          <button
            onClick={() => onRemove(item.cuisineId)}
            className="w-10 h-10 bg-red-500 text-white rounded-full flex items-center justify-center shadow transition duration-200 transform hover:bg-red-600 hover:scale-105"
          >
            -
          </button>
        )}
      </div>
    </div>
  );
  
};

export default CartItem;



