import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faClock, faUtensils, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import API_BASE_URL from '../../apiConfig';
import { calculateAverageRating } from './calculateAverageRating';



const RestaurantCard = ({ restaurant }) => {
  const { id, name, openOrClosed, operatingHours, cuisineType, location } = restaurant;
  const [imageUrl, setImageUrl] = useState('');
  const [averageRating, setAverageRating] = useState(null); // State to store the average rating
  const isOpen = openOrClosed === true;

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/restaurant/downloadImage/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
        if (response.ok) {
          const blob = await response.blob();
          const imageUrl = URL.createObjectURL(blob);
          setImageUrl(imageUrl);
        } else {
          console.error('Failed to fetch image');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };
  
    const fetchRatings = async () => {
      const rating = await calculateAverageRating(id);
      setAverageRating(rating);
    };
  
    fetchImage();
    fetchRatings();
  }, [id]);
  
  const combinedLocation = `${location.addressLine1}, ${location.city}, ${location.state}`;
  const displayLocation =
    combinedLocation.length > 24
      ? combinedLocation.slice(0, 24) + "..."
      : combinedLocation;

  return (
    <div className="rounded-lg overflow-hidden shadow-lg relative bg-white hover:shadow-2xl transition-shadow duration-300 ease-in-out">
      {imageUrl ? (
        <img
          src={imageUrl}
          alt={name}
          className={`w-full h-48 md:h-64 object-cover object-center ${!isOpen ? 'opacity-50 grayscale' : ''}`}
        />
      ) : (
        <div className="w-full h-48 md:h-64 bg-gray-200 flex items-center justify-center">
          <span className="text-gray-500">Loading image...</span>
        </div>
      )}
      {!isOpen && (
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <span className="text-white font-bold text-lg">Closed</span>
        </div>
      )}
      <div className="p-4">
        <div className="flex justify-between items-center">
          <h3 className="text-lg md:text-xl font-semibold text-gray-900 truncate">{name}</h3>
          <button className="text-gray-400 hover:text-red-500 focus:outline-none transition-colors">
            <FontAwesomeIcon icon={faHeart} />
          </button>
        </div>
        <div className="flex items-center text-sm text-gray-600 mt-1">
          <FontAwesomeIcon icon={faUtensils} className="mr-2 text-gray-400" />
          <p>{cuisineType.length > 24 ? `${cuisineType.substring(0, 24)}...` : cuisineType}</p>
        </div>

        <div className="flex items-center text-sm text-gray-600 mt-1">
          <FontAwesomeIcon icon={faClock} className="mr-2 text-gray-400" />
          <p>{operatingHours}</p>
        </div>
        <div className="flex justify-between items-center text-sm text-gray-600 mt-1">
          <div className="flex items-center">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2 text-gray-400" />
            <p>{displayLocation}</p>
          </div>
          <p className="text-yellow-500 font-medium">⭐ {averageRating ? averageRating : 'None'}</p>
        </div>
      </div>
    </div>
  );
  
};

export default RestaurantCard;
