import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-gray-200 py-8">
      <div className="container mx-auto px-6">
        <div className="flex flex-wrap justify-between">
          
          {/* Helpful Links */}
          <div className="w-full sm:w-1/2 mb-6">
            <h3 className="text-xl font-bold mb-4 text-white">Helpful Links</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/about-us" className="hover:underline transition duration-300 hover:text-[#7F00FF]">About Us</Link>
              </li>
              <li>
                <Link to="/development-in-progress" className="hover:underline transition duration-300 hover:text-[#7F00FF]">FAQ</Link>
              </li>
              <li>
                <Link to="/development-in-progress" className="hover:underline transition duration-300 hover:text-[#7F00FF]">Support</Link>
              </li>
              <li>
                <Link to="/development-in-progress" className="hover:underline transition duration-300 hover:text-[#7F00FF]">Terms & Conditions</Link>
              </li>
              <li>
                <Link to="/development-in-progress" className="hover:underline transition duration-300 hover:text-[#7F00FF]">Privacy Policy</Link>
              </li>
            </ul>
          </div>

          {/* Contact Info */}
          <div className="w-full sm:w-1/2 mb-6">
            <h3 className="text-xl font-bold mb-4 text-white">Contact Us</h3>
            <p className="mb-2">Email: <a href="mailto:support@restoura.com" className="hover:underline transition duration-300 hover:text-[#7F00FF]">support@restoura.com</a></p>
            {/* <p className="mb-2">Phone: (123) 456-7890</p> */}
            <p>Address: Ontario, Canada</p>
          </div>

          {/* Social Media Links (optional) */}
          {/* <div className="w-full sm:w-1/3 mb-6">
            <h3 className="text-xl font-bold mb-4 text-white">Follow Us</h3>
            <ul className="flex space-x-4">
              <li>
                <Link to="/development-in-progress" className="hover:text-[#7F00FF] transition duration-300">Facebook</Link>
              </li>
              <li>
                <Link to="/development-in-progress" className="hover:text-[#7F00FF] transition duration-300">Twitter</Link>
              </li>
              <li>
                <Link to="/development-in-progress" className="hover:text-[#7F00FF] transition duration-300">Instagram</Link>
              </li>
            </ul>
          </div> */}
          
        </div>

        <div className="mt-6 border-t border-gray-700 pt-4 text-center">
          <p className="text-sm">&copy; 2024 Restoura. All rights reserved.</p>
          <p className="text-sm">Developed by <span className="font-semibold text-white"> Peytosoft</span></p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
