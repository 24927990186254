import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, StandaloneSearchBox, useLoadScript, Marker } from '@react-google-maps/api';
import API_BASE_URL from '../../apiConfig';
import { useParams } from 'react-router-dom';

const libraries = ['places'];

const EditRestaurantMap = ({ onLocationSelect }) => {
  const [currentPosition, setCurrentPosition] = useState(null);
  const [placeName, setPlaceName] = useState("");
  const searchBoxRef = useRef(null);
  const { restaurantId } = useParams();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: libraries,
  });

  useEffect(() => {
    if (isLoaded) {
      fetchRestaurantLocation();
    }
  }, [isLoaded]);

  const fetchRestaurantLocation = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/restaurant/getRestaurant?restaurantId=${restaurantId}`);
      const data = await response.json();

      const { latitude, longitude, placeName } = data;
      if (latitude && longitude && placeName) {
        const location = { lat: parseFloat(latitude), lng: parseFloat(longitude) };
        setCurrentPosition(location);
        setPlaceName(placeName);
        
        // Store fetched data in localStorage
        localStorage.setItem("latitude", latitude);
        localStorage.setItem("longitude", longitude);
        localStorage.setItem("placeName", placeName);
      }
    } catch (error) {
      console.error("Failed to fetch restaurant location:", error);
    }
  };

  const fetchPlaceName = async (position) => {
    if (!window.google || !window.google.maps) {
      console.error("Google Maps API not loaded.");
      return;
    }

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: position }, (results, status) => {
      if (status === "OK" && results[0]) {
        const place = results[0].formatted_address;
        setPlaceName(place);

        // Store in localStorage
        localStorage.setItem("placeName", place);
        localStorage.setItem("latitude", position.lat);
        localStorage.setItem("longitude", position.lng);
      } else {
        console.error("Geocoder failed due to: " + status);
      }
    });
  };

  const handleSearchChange = (place) => {
    const newLocation = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };
    setCurrentPosition(newLocation);
    setPlaceName(place.formatted_address);
    onLocationSelect({ placeName: place.formatted_address, ...newLocation });
    
    // Store in localStorage
    localStorage.setItem("placeName", place.formatted_address);
    localStorage.setItem("latitude", newLocation.lat);
    localStorage.setItem("longitude", newLocation.lng);
  };

  const handleMapClick = (e) => {
    const newPosition = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    setCurrentPosition(newPosition);
    fetchPlaceName(newPosition);
  };

  return (
    <div className="map-container" style={{ position: "relative", width: "100%", height: "400px" }}>
      <StandaloneSearchBox
        onLoad={(ref) => (searchBoxRef.current = ref)}
        onPlacesChanged={() => {
          const places = searchBoxRef.current.getPlaces();
          if (places && places.length > 0) {
            handleSearchChange(places[0]);
          }
        }}
      >
        <input
          type="text"
          placeholder="Search for a place..."
          style={{
            width: "300px",
            padding: "10px",
            position: "absolute",
            top: "10px",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: "10",
            borderRadius: "4px",
            border: "1px solid #ccc",
          }}
          value={placeName}
          onChange={(e) => setPlaceName(e.target.value)}
        />
      </StandaloneSearchBox>
      {isLoaded ? (
        <GoogleMap
          center={currentPosition}
          zoom={15}
          mapContainerStyle={{ width: "100%", height: "100%" }}
          onClick={handleMapClick}
          options={{
            fullscreenControl: false, // Disable fullscreen control
          }}
        >
          {currentPosition && (
            <Marker
              position={currentPosition}
              label="Restaurant"
            />
          )}
        </GoogleMap>
      ) : (
        <div>Loading map...</div>
      )}
    </div>
  );
};

export default EditRestaurantMap;
