import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, DirectionsRenderer, Marker } from '@react-google-maps/api';
import { TextField, Button } from '@mui/material';
import API_BASE_URL from '../../apiConfig';

const libraries = ['places'];
const MapDirections = ({ restaurantId }) => {
  const [origin, setOrigin] = useState(null);
  const [originAddress, setOriginAddress] = useState("");
  const [destination, setDestination] = useState(null);
  const [destinationAddress, setDestinationAddress] = useState("");
  const [directions, setDirections] = useState(null);
  const [distance, setDistance] = useState(null); 

  const mapContainerStyle = {
    width: '100%',
    height: '400px',
  };

  useEffect(() => {
    const fetchDestination = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/restaurant/getRestaurant?restaurantId=${restaurantId}`);
        if (response.ok) {
          const data = await response.json();
          setDestination({ lat: parseFloat(data.latitude), lng: parseFloat(data.longitude) });
          setDestinationAddress(data.placeName);
        } else {
          console.error('Failed to fetch destination');
        }
      } catch (error) {
        console.error('Error fetching destination:', error);
      }
    };

    fetchDestination();

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const userLocation = { lat: latitude, lng: longitude };
        setOrigin(userLocation);
        // Check if Google Maps is loaded before calling reverseGeocode
        if (window.google) {
          reverseGeocode(userLocation, setOriginAddress);
        }
    
      },
      (error) => console.error('Error fetching user location:', error)
    );
  }, [restaurantId]);

  const reverseGeocode = (coords, setAddress) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: coords }, (results, status) => {
      if (status === 'OK' && results[0]) {
        setAddress(results[0].formatted_address);
      } else {
        console.error('Reverse geocoding failed:', status);
      }
    });
  };

  const handleMapClick = (e) => {
    const clickedLocation = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    setOrigin(clickedLocation);
    reverseGeocode(clickedLocation, setOriginAddress);

  };

  const handleDirectionCalculation = () => {
    if (origin && destination) {
      const DirectionsService = new window.google.maps.DirectionsService();
      DirectionsService.route(
        {
          origin,
          destination,
          travelMode: window.google.maps.TravelMode.DRIVING, //WALKING 
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result);
            setDistance(result.routes[0].legs[0].distance.text);  // Set distance in miles or kilometers
          } else {
            console.error(`Error fetching directions: ${status}`);
          }
        }
      );
    }
  };
  
  const handleOriginAddressChange = async (e) => {
    const newAddress = e.target.value;
    setOriginAddress(newAddress);

    // Geocode the address to update the origin location
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: newAddress }, (results, status) => {
      if (status === 'OK' && results[0]) {
        const newLocation = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        };
        setOrigin(newLocation);
      } else {
        console.error('Geocoding failed:', status);
      }
    });
  };

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries={libraries}>
      <div className="container mx-auto px-4 md:px-8 py-2">
        <h3 className="text-xl font-semibold mb-2 text-center">
          {destinationAddress}
        </h3>
        {distance && (
          <p className="text-lg text-center text-red-600 mb-4">
            Distance: {distance}
          </p>
        )}
        
        <div className="flex flex-col md:flex-row gap-4 justify-center items-center mb-4">
          <TextField
            label="Your Location"
            variant="outlined"
            value={originAddress}
            onChange={handleOriginAddressChange}
            className="w-full md:w-1/3"
          />
          <TextField
            label="Restaurant Location"
            variant="outlined"
            value={destinationAddress}
            InputProps={{ readOnly: true }}
            className="w-full md:w-1/3"
          />
          <Button
            variant="contained"
            onClick={handleDirectionCalculation}
            className="w-full md:w-auto bg-blue-600 hover:bg-blue-700 text-white"
          >
            Show Directions
          </Button>
        </div>
        <p className="text-center text-gray-600">
        *Directions will start from current your location. Click map if you want to change*
        </p>
        <div className="relative mb-8">
          <GoogleMap
            mapContainerStyle={{ ...mapContainerStyle, paddingBottom: '10px' }}
            center={origin || { lat: 0, lng: 0 }}
            zoom={12}
            onClick={handleMapClick}
          >
            {origin && <Marker position={origin} label="Me" />}
            {destination && <Marker position={destination} />}
            {directions && <DirectionsRenderer directions={directions} />}
          </GoogleMap>
        </div>
      </div>
    </LoadScript>
  );
};

export default MapDirections;