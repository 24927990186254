import React, { useState, useEffect } from 'react';
import API_BASE_URL from '../../apiConfig';
// import { useNavigate } from 'react-router-dom';


const MenuManagement = () => {
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurantId, setSelectedRestaurantId] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  
  const [itemDetails, setItemDetails] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [imageFile, setImageFile] = useState(null);
  const [newMenuItem, setNewMenuItem] = useState({
    cuisineName: '',
    category: '',
    description: '',
    price: '',
    estimatedPreparationTime:'',
    availability: '',
    isHalal: false,
    isVegetarian: false,
    isKosher: false,
    isGlutenFree: false,
    isMSGFree: false,
    isNutFree: false,
    isOrganic: false,
    isNonGMO: false,
    isSpicy: false,
  });

  const userId = parseInt(localStorage.getItem('userId'), 10);
  const bearerToken = localStorage.getItem('token');
  // const navigate = useNavigate();

  useEffect(() => {
    const fetchRestaurants = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/restaurant/allRestaurants`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch restaurants');
        }

        const textData = await response.text();
        const restaurantsData = JSON.parse(textData);
        const vendorRestaurants = restaurantsData.filter(
          (restaurant) => parseInt(restaurant.ownerId, 10) === userId
        );

        setRestaurants(vendorRestaurants);
      } catch (error) {
        console.error('Error fetching restaurants:', error);
      }
    };

    fetchRestaurants();
  }, [userId, bearerToken]);

  const fetchMenuItems = async (restaurantId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/cuisine/allCuisines/${restaurantId}`, {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      });

      const cuisines = await response.json();

      // Fetch images for all cuisines
      const cuisinesWithImages = await Promise.all(
        cuisines.map(async (cuisine) => {
          const imageResponse = await fetch(
            `${API_BASE_URL}/cuisine/downloadImage/${cuisine.id}`,
            {
              headers: {
                Authorization: `Bearer ${bearerToken}`,
              },
            }
          );
          const imageBlob = await imageResponse.blob();
          const imageUrl = URL.createObjectURL(imageBlob);

          return { ...cuisine, imageUrl };
        })
      );

      setMenuItems(cuisinesWithImages);
    } catch (error) {
      console.error('Error fetching menu items:', error);
    }
  };

  const handleRestaurantSelect = (event) => {
    const restaurantId = Number(event.target.value);
    setSelectedRestaurantId(restaurantId);
    fetchMenuItems(restaurantId);
  };

  const handleNewMenuInputChange = (field, value) => {
    setNewMenuItem((prevItem) => ({
      ...prevItem,
      [field]: value,
    }));
  };

  const handleImageUploadChange = (event) => {
    setImageFile(event.target.files[0]);
  };

  const handleAddNewMenu = async () => {
    setIsSubmitting(true); // Disable the button
    if (!newMenuItem.cuisineName || !newMenuItem.category || !newMenuItem.price) {
      alert('Please fill in all required fields marked with *');
      setIsSubmitting(false); // Re-enable the button
      return;
    }

    try {
      // Add new cuisine
      const response = await fetch(`${API_BASE_URL}/cuisine/addCuisine/${selectedRestaurantId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${bearerToken}`,
        },
        body: JSON.stringify(newMenuItem),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error adding new menu item:', errorText);
        alert(`Error: ${errorText}`);
        setIsSubmitting(false); // Re-enable the button
        return;
      }

      // Get the created cuisine ID
      const cuisineId = await response.text();

      // Upload the image
      if (imageFile) {
        const formData = new FormData();
        formData.append('image', imageFile);

        const imageUploadResponse = await fetch(
          `${API_BASE_URL}/cuisine/uploadImage/${cuisineId}`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${bearerToken}`,
            },
            body: formData,
          }
        );

        if (!imageUploadResponse.ok) {
          throw new Error(`Failed to upload image: ${imageUploadResponse.statusText}`);
        }
      }

      // Refresh menu items after adding new menu and uploading image
      fetchMenuItems(selectedRestaurantId);

      // Reset input fields
      setNewMenuItem({
        cuisineName: '',
        category: '',
        description: '',
        price: '',
        estimatedPreparationTime:'',
        availability: '',
        isHalal: false,
        isVegetarian: false,
        isKosher: false,
        isGlutenFree: false,
        isMSGFree: false,
        isNutFree: false,
        isOrganic: false,
        isNonGMO: false,
        isSpicy: false,
      });
      setImageFile(null);
      alert('Cuisine created successfully!');
    } catch (error) {
      console.error('Error adding new menu item:', error);
    }finally {
      setIsSubmitting(false); // Re-enable the button
    }
  };

  const navigateToEditMenu = (cuisineName, cuisineId) => {
    alert('Edit functionality to be implemented.');
  };

  // Function to fetch menu item details by ID
  const fetchMenuItemDetails = async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}/cuisine/getCuisineById/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${bearerToken}`, 
          'Content-Type': 'application/json' 
        }
      });

      if (!response.ok) {
        throw new Error(`Error fetching cuisine details: ${response.status}`);
      }
      const data = await response.json();

      // Update the state with the fetched item details
      setItemDetails(data);
      setIsModalOpen(true); 
    } catch (error) {
      console.error("Error fetching menu item details:", error);
    }
  };


  
  return (
    <div className="overflow-x-auto p-6 bg-gray-100 min-h-screen">
      {/* Header Section */}
      <div className="bg-gradient-to-r from-blue-600 to-indigo-800 py-8 shadow-lg rounded-t-lg text-center">
        <h2 className="text-4xl font-bold text-white tracking-wide">Menu Management</h2>
      </div>

      {/* Restaurant Selector */}
      <div className="flex flex-col md:flex-row justify-between items-center my-6 bg-white p-6 rounded-lg shadow-md">
        <label className="text-lg font-semibold text-gray-700 mb-2 md:mb-0">
          {selectedRestaurantId ? "Select a restaurant to add menu items" : (
            <span className="text-red-500">Please select a restaurant to continue*</span>
          )}
        </label>
        <select
          onChange={handleRestaurantSelect}
          className="border rounded p-3 text-gray-800 bg-white focus:ring focus:ring-blue-300 transition duration-200"
          value={selectedRestaurantId || ''}
        >
          <option value="">--Select Restaurant--</option>
          {restaurants
            .filter((restaurant) => restaurant.status === "Approved")
            .map((restaurant) => (
              <option key={restaurant.id} value={restaurant.id}>
                {restaurant.name}
              </option>
            ))}
        </select>
      </div>

      {/* Menu Item Input Form */}
      <div className="bg-white p-8 rounded-lg shadow-lg">
        <h3 className="text-2xl font-semibold text-gray-700 mb-4">Add New Menu Item</h3>

        {/* Basic Information Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <input
            type="text"
            placeholder="Cuisine Name*"
            value={newMenuItem.cuisineName}
            onChange={(e) => handleNewMenuInputChange("cuisineName", e.target.value)}
            className="w-full p-3 border rounded-lg focus:ring focus:ring-blue-300 transition duration-200"
            required
            disabled={!selectedRestaurantId}
          />
          <input
            type="text"
            placeholder="Category*"
            value={newMenuItem.category}
            onChange={(e) => handleNewMenuInputChange("category", e.target.value)}
            className="w-full p-3 border rounded-lg focus:ring focus:ring-blue-300 transition duration-200"
            required
            disabled={!selectedRestaurantId}
          />
          <input
            type="text"
            placeholder="Price in CAD*"
            value={newMenuItem.price}
            onChange={(e) => handleNewMenuInputChange("price", e.target.value)}
            className="w-full p-3 border rounded-lg focus:ring focus:ring-blue-300 transition duration-200"
            required
            disabled={!selectedRestaurantId}
          />

          <select
            value={newMenuItem.availability}
            onChange={(e) => handleNewMenuInputChange("availability", e.target.value)}
            className="w-full p-3 border rounded-lg focus:ring focus:ring-blue-300 transition duration-200"
            required
            disabled={!selectedRestaurantId}
          >
            <option value="" disabled>Select Availability*</option>
            <option value="in stock">In Stock</option>
            <option value="out of stock">Out of Stock</option>
          </select>

          <input
            type="text"
            placeholder="Estimated Preparation Time (ex: 1hr 30min)"
            value={newMenuItem.estimatedPreparationTime}
            onChange={(e) => handleNewMenuInputChange("estimatedPreparationTime", e.target.value)}
            className="w-full p-3 border rounded-lg focus:ring focus:ring-blue-300 transition duration-200"
            min="1"
            required
            disabled={!selectedRestaurantId}
          />
          <textarea
            placeholder="Description"
            value={newMenuItem.description}
            onChange={(e) => handleNewMenuInputChange("description", e.target.value)}
            className="w-full p-3 border rounded-lg focus:ring focus:ring-blue-300 transition duration-200 h-24 resize-none"
            disabled={!selectedRestaurantId}
          />
        </div>



        {/* Dietary Options Section */}
        <div className="mb-6">
          <h4 className="font-semibold text-gray-700 mb-4">Dietary & Availability Options</h4>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
            {["isHalal", "isVegetarian", "isKosher", "isGlutenFree", "isMSGFree", "isNutFree", "isOrganic", "isNonGMO", "isSpicy"].map((option) => (
              <label key={option} className="inline-flex items-center">
                <input
                  type="checkbox"
                  checked={newMenuItem[option]}
                  onChange={(e) => handleNewMenuInputChange(option, e.target.checked)}
                  className="h-4 w-4 text-blue-600"
                  disabled={!selectedRestaurantId}
                />
                <span className="ml-2 text-gray-700">{option.replace("is", "").replace(/([A-Z])/g, " $1")}</span>
              </label>
            ))}
          </div>
        </div>

        {/* Image Upload Section */}
        <div className="mb-6">
          <label htmlFor="image-upload" className="block font-semibold text-gray-700 mb-2">
            Upload Image <span className="text-red-500">*</span>
          </label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUploadChange}
            className="w-full p-2 border rounded-lg focus:ring focus:ring-blue-300 transition duration-200"
            id="image-upload"
            required
            disabled={!selectedRestaurantId}
          />
        </div>

        {/* Submit Button */}
        <button
          onClick={handleAddNewMenu}
          className="w-full py-3 bg-gradient-to-r from-blue-600 to-indigo-600 text-white rounded-lg shadow-md hover:shadow-lg focus:ring focus:ring-blue-300 transition duration-200"
          disabled={!selectedRestaurantId || !newMenuItem.cuisineName || !newMenuItem.category || !newMenuItem.price || !newMenuItem.availability || !imageFile || isSubmitting}
        >
          {isSubmitting ? "Adding Menu..." : "Add New Menu"}
        </button>
      </div>

      <div className="overflow-x-auto mt-8">
        {/* Menu Items Table */}
        <table className="min-w-full bg-white border border-gray-300 shadow-lg rounded-lg table-auto mt-8">
        <thead className="bg-gray-200">
          <tr>
            <th className="py-4 px-3 sm:px-6 border-b text-left font-semibold text-xs sm:text-sm">ID</th>
            <th className="py-4 px-3 sm:px-6 border-b text-left font-semibold text-xs sm:text-sm">Name</th>
            <th className="py-4 px-3 sm:px-6 border-b text-left font-semibold text-xs sm:text-sm">Category</th>
            <th className="py-4 px-3 sm:px-6 border-b text-left font-semibold text-xs sm:text-sm">Price</th>
            <th className="py-4 px-3 sm:px-6 border-b text-left font-semibold text-xs sm:text-sm">Availability</th>
            <th className="py-4 px-3 sm:px-6 border-b text-left font-semibold text-xs sm:text-sm">Image</th>
            <th className="py-4 px-3 sm:px-6 border-b text-left font-semibold text-xs sm:text-sm">Actions</th>
          </tr>
        </thead>
        <tbody>
          {menuItems.map((item) => (
            <tr key={item.id}>
              <td className="py-4 px-3 sm:px-6 border-b text-xs sm:text-sm">{item.id}</td>
              
              {/* Name Column with Character Limit */}
              <td className="py-4 px-3 sm:px-6 border-b text-xs sm:text-sm">
                {item.cuisineName.length > 24 ? item.cuisineName.slice(0, 24) + '...' : item.cuisineName}
              </td>
              
              {/* Category Column with Character Limit */}
              <td className="py-4 px-3 sm:px-6 border-b text-xs sm:text-sm">
                {item.category.length > 24 ? item.category.slice(0, 24) + '...' : item.category}
              </td>
              
              <td className="py-4 px-3 sm:px-6 border-b text-xs sm:text-sm">${item.price}</td>
              <td className="py-4 px-3 sm:px-6 border-b text-xs sm:text-sm">{item.availability}</td>
              <td className="py-4 px-3 sm:px-6 border-b text-xs sm:text-sm">
                {item.imageUrl && (
                  <img
                    src={item.imageUrl}
                    alt={item.cuisineName}
                    className="w-12 h-12 sm:w-16 sm:h-16 object-cover rounded-lg"
                  />
                )}
              </td>
                  <td className="py-4 px-3 sm:px-6 border-b text-xs sm:text-sm">
                    <span className="inline-flex overflow-hidden rounded-md border bg-white shadow-sm">
                      {/* View Details Button */}
                      <button
                        onClick={() => fetchMenuItemDetails(item.id)}
                        className="inline-block p-2 sm:p-3 text-gray-700 hover:bg-gray-50 focus:relative"
                        title="View Details"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="h-4 w-4 sm:h-5 sm:w-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-.173.567-.38 1.113-.62 1.63a11.968 11.968 0 01-2.38 3.403A11.96 11.96 0 0112 19a11.96 11.96 0 01-6.542-2.458A11.968 11.968 0 013.08 13.63a11.968 11.968 0 01-.62-1.63z"
                          />
                        </svg>
                      </button>
                      
                      {/* Edit Button */}
                      <button
                        onClick={() => navigateToEditMenu(item.cuisineName, item.id)}
                        className="inline-block border-e p-2 sm:p-3 text-gray-700 hover:bg-gray-50 focus:relative"
                        title="Edit Menu"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="h-4 w-4 sm:h-5 sm:w-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                          />
                        </svg>
                      </button>
                    
                    </span>
                  </td>

                </tr>
              ))}
            </tbody>
            </table>
      </div>

      {/* Modal Component for Menu Details */}
      {isModalOpen && itemDetails && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg w-11/12 md:w-3/4 lg:w-1/2 p-8 shadow-2xl transition transform scale-100 duration-300 overflow-y-auto max-h-[80vh]">
            <h3 className="text-3xl font-bold text-gray-800 mb-6 text-center">Menu Item Details</h3>
            {/* Close Button */}
            <button
              onClick={() => setIsModalOpen(false)}
              className="absolute top-2 right-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition duration-200 shadow-md w-8 h-8 flex items-center justify-center"
            >
              &times;
            </button>
            
            <div className="space-y-4">
              <p className="text-lg font-semibold">
                <span className="text-gray-600">Cuisine Name: </span>
                <span className="text-gray-800">{itemDetails.cuisineName}</span>
              </p>
              <p className="text-lg font-semibold">
                <span className="text-gray-600">Category: </span>
                <span className="text-gray-800">{itemDetails.category}</span>
              </p>
              <p className="text-lg font-semibold">
                <span className="text-gray-600">Price: </span>
                <span className="text-gray-800">${itemDetails.price}</span>
              </p>
              <p className="text-lg font-semibold">
                <span className="text-gray-600">Estimated Preparation Time: </span>
                <span className="text-gray-800">{itemDetails.estimatedPreparationTime}</span>
              </p>
              <p className="text-lg font-semibold">
                <span className="text-gray-600">Availability: </span>
                <span className="text-gray-800">{itemDetails.availability}</span>
              </p>
              <p className="text-lg font-semibold">
                <span className="text-gray-600">Description: </span>
                <span className="text-gray-800 break-words w-full max-w-md">{itemDetails.description}</span>
              </p>

              {/* Dietary Information */}
              <p className="text-lg font-semibold">Dietary Information:</p>
              <ul className="grid grid-cols-2 gap-2 list-inside text-gray-700 ml-4">
                  {itemDetails.isHalal && <li>Halal</li>}
                  {itemDetails.isVegetarian && <li>Vegetarian</li>}
                  {itemDetails.isKosher && <li>Kosher</li>}
                  {itemDetails.isGlutenFree && <li>Gluten-Free</li>}
                  {itemDetails.isMSGFree && <li>MSG-Free</li>}
                  {itemDetails.isNutFree && <li>Nut-Free</li>}
                  {itemDetails.isOrganic && <li>Organic</li>}
                  {itemDetails.isNonGMO && <li>Non-GMO</li>}
                  {itemDetails.isSpicy && <li>Spicy</li>}
                </ul>

            </div>


          </div>
        </div>

      )}


      
    </div>
  );
};

export default MenuManagement;
