import React from 'react';
import Navigation from './Navigation';
import { Route, Routes } from 'react-router-dom';
import CustomerDetails from './CustomerDetails';
// import AddressManagement from './AddressManagement'; faAddressCard 
// import PaymentMethod from './PaymentMethod'; faCreditCard
import { faUser} from '@fortawesome/free-solid-svg-icons';

const userId = localStorage.getItem('userId');

// Menu with Customer Details and Payment Method
const customerDetailsMenu = [
  { title: "Customer Details", icon: faUser, path: `/customer-profile/my-profile/${userId}` },
  // { title: "Address Management", icon: faAddressCard, path: `/customer-profile/address-management/${userId}` },
  // { title: "Payment Method", icon: faCreditCard, path: `/customer-profile/payment-method/${userId}` }
];

function Profile() {
  return (
    <div className="flex flex-col md:flex-row mt-20"> {/* Responsive layout: Column on mobile, row on larger screens */}
      <Navigation menu={customerDetailsMenu} />
      <div className="flex-1 p-4 md:p-8"> {/* Adjust padding for mobile */}
        <Routes>
          <Route path="my-profile/:id" element={<CustomerDetails />} />
          {/* <Route path="address-management/:id" element={<AddressManagement />} /> */}
          {/* <Route path="payment-method/:id" element={<PaymentMethod />} /> */}
        </Routes>
      </div>
    </div>
  );
}

export default Profile;
