import React, { useEffect, useState } from 'react';
import API_BASE_URL from '../../apiConfig'; // Import the base API URL

const AdminRestaurantApproval = () => {
  const [restaurants, setRestaurants] = useState([]);
  const [filteredRestaurants, setFilteredRestaurants] = useState([]); // For filtered data
  const [selectedStatus, setSelectedStatus] = useState(''); // Filter value
  const [selectedRestaurant, setSelectedRestaurant] = useState(null); // For modal details
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility
  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchRestaurants(); // Fetch the restaurants when the component loads
  }, []);

  // Function to fetch all restaurants
  const fetchRestaurants = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/restaurant/allRestaurants`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setRestaurants(data);
        setFilteredRestaurants(data); // Initialize filtered list
      } else {
        console.error('Failed to fetch restaurants');
      }
    } catch (error) {
      console.error('Error fetching restaurants:', error);
    }
  };

  // Function to handle approval or rejection of a restaurant
  const handleApproval = async (restaurant, action) => {
    const confirmation = window.confirm(`Are you sure you want to ${action} this restaurant?`);
    if (!confirmation) return;

    const newStatus = action === 'approve' ? 'Approved' : 'Rejected';

    try {
      const response = await fetch(`${API_BASE_URL}/restaurant/updateStatus/${restaurant.id}?status=${newStatus}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        alert(`Restaurant ${newStatus.toLowerCase()} successfully`);
        fetchRestaurants(); // Refresh the restaurant list after status change
      } else {
        console.error('Failed to update status');
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  // Function to handle status filter
  const handleStatusFilter = (status) => {
    setSelectedStatus(status);
    if (status === '') {
      setFilteredRestaurants(restaurants); // Reset to full list
    } else {
      setFilteredRestaurants(restaurants.filter((restaurant) => restaurant.status === status));
    }
  };

  // Function to handle details button click (open modal)
  const handleShowDetails = (restaurant) => {
    setSelectedRestaurant(restaurant);
    setIsModalOpen(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedRestaurant(null);
  };

  return (
    <div className="p-6">
      <h2 className="text-3xl font-bold mb-6 text-center">Admin Restaurant Approval</h2>

      {/* Filter radio buttons */}
      <div className="mb-6">
        <label className="font-semibold text-lg mr-6">Filter by status:</label>
        <div className="inline-flex space-x-6 flex-wrap">
          <label className="flex items-center space-x-2">
            <input
              type="radio"
              name="status"
              value=""
              checked={selectedStatus === ''}
              onChange={() => handleStatusFilter('')}
              className="form-radio h-4 w-4 text-blue-600"
            />
            <span className="text-gray-700">All</span>
          </label>
          <label className="flex items-center space-x-2">
            <input
              type="radio"
              name="status"
              value="Waiting for Approval"
              checked={selectedStatus === 'Waiting for Approval'}
              onChange={() => handleStatusFilter('Waiting for Approval')}
              className="form-radio h-4 w-4 text-blue-600"
            />
            <span className="text-gray-700">Waiting for Approval</span>
          </label>
          <label className="flex items-center space-x-2">
            <input
              type="radio"
              name="status"
              value="Approved"
              checked={selectedStatus === 'Approved'}
              onChange={() => handleStatusFilter('Approved')}
              className="form-radio h-4 w-4 text-blue-600"
            />
            <span className="text-gray-700">Approved</span>
          </label>
          <label className="flex items-center space-x-2">
            <input
              type="radio"
              name="status"
              value="Rejected"
              checked={selectedStatus === 'Rejected'}
              onChange={() => handleStatusFilter('Rejected')}
              className="form-radio h-4 w-4 text-blue-600"
            />
            <span className="text-gray-700">Rejected</span>
          </label>
        </div>
      </div>

      {/* Table */}
      <div className="overflow-x-auto shadow-md rounded-lg">
        <table className="min-w-full bg-white">
          <thead className="bg-gray-200">
            <tr className="text-left text-sm font-semibold text-gray-700">
              <th className="py-3 px-6">Restaurant ID</th>
              <th className="py-3 px-6">Restaurant Name</th>
              <th className="py-3 px-6">Status</th>
              <th className="py-3 px-6">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredRestaurants
              .sort((a, b) => a.status === 'Waiting for Approval' ? -1 : 1) // Sort by 'Waiting for Approval' status first
              .map((restaurant) => (
                <tr key={restaurant.id} className="border-b hover:bg-gray-100">
                  <td className="py-3 px-6">{restaurant.id}</td>
                  <td className="py-3 px-6">{restaurant.name}</td>
                  <td className="py-3 px-6">
                    <span
                      className={`px-3 py-1 rounded-full text-sm font-medium ${
                        restaurant.status === 'Approved' ? 'bg-green-200 text-green-800' :
                        restaurant.status === 'Waiting for Approval' ? 'bg-yellow-200 text-yellow-800' :
                        'bg-red-200 text-red-800'
                      }`}
                    >
                      {restaurant.status}
                    </span>
                  </td>
                  <td className="py-3 px-6">
                    <button
                      onClick={() => handleShowDetails(restaurant)}
                      className="bg-gray-500 text-white px-4 py-1 rounded mr-2 hover:bg-gray-600 transition duration-300"
                    >
                      Details
                    </button>
                    {restaurant.status === 'Waiting for Approval' && (
                      <>
                        <button
                          onClick={() => handleApproval(restaurant, 'approve')}
                          className="bg-blue-500 text-white px-4 py-1 rounded mr-2 hover:bg-blue-600 transition duration-300"
                        >
                          Approve
                        </button>
                        <button
                          onClick={() => handleApproval(restaurant, 'reject')}
                          className="bg-red-500 text-white px-4 py-1 rounded hover:bg-red-600 transition duration-300"
                        >
                          Reject
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {/* Modal for restaurant details */}
      {isModalOpen && selectedRestaurant && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-lg w-full shadow-lg">
            <h3 className="text-xl font-bold mb-4 text-center">Restaurant Details</h3>
            <div className="text-sm">
              <p><strong>Name:</strong> {selectedRestaurant.name}</p>
              <p><strong>Cuisine Type:</strong> {selectedRestaurant.cuisineType}</p>
              <p><strong>Description:</strong> {selectedRestaurant.description}</p>
              <p><strong>Operating Hours:</strong> {selectedRestaurant.operatingHours}</p>
              <p><strong>Party Order Available:</strong> {selectedRestaurant.partyOrderAvailable ? 'Yes' : 'No'}</p>
              <p><strong>Off-Hour Delivery:</strong> {selectedRestaurant.offHourDeliveryAvailable ? 'Yes' : 'No'}</p>
              <p><strong>Status:</strong> {selectedRestaurant.openOrClosed ? 'Open' : 'Closed'}</p>
              <p><strong>Email:</strong> {selectedRestaurant.email}</p>
              <p><strong>Contact No:</strong> {selectedRestaurant.contactNo}</p>
              <p><strong>Address:</strong> {selectedRestaurant.addressLine1}, {selectedRestaurant.addressLine2}, {selectedRestaurant.city}, {selectedRestaurant.state}, {selectedRestaurant.postalCode}, {selectedRestaurant.country}</p>
            </div>
            <button
              onClick={handleCloseModal}
              className="mt-4 bg-red-500 text-white px-4 py-2 rounded w-full hover:bg-red-600 transition duration-300"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>

  );
};

export default AdminRestaurantApproval;
