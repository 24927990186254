import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import API_BASE_URL from '../../apiConfig';

const Register = () => {
    const [email, setEmail] = useState('');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [role, setRole] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false); // Loading state
    const [passwordMatchError, setPasswordMatchError] = useState('');
    const [passwordLengthError, setPasswordLengthError] = useState('');
    // const [registrationSuccess, setRegistrationSuccess] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const navigate = useNavigate();

    const MIN_PASSWORD_LENGTH = 8;

    const handleRegister = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Set loading state to true
        // Reset errors before validation
        setError('');
        setPasswordMatchError('');
        setPasswordLengthError('');

        if (email === '' || password === '' || confirmPassword === '' || role === ''|| userName === '') {
            setError('Please fill in all fields.');
            setIsLoading(false);
            return;
        }

        if (password.length < MIN_PASSWORD_LENGTH) {
            setPasswordLengthError(`Password must be at least ${MIN_PASSWORD_LENGTH} characters long.`);
            setIsLoading(false);
            return;
        }

        if (password !== confirmPassword) {
            setPasswordMatchError('Passwords do not match.');
            setIsLoading(false);
            return;
        }

        const requestBody = {
            email: email,
            password: password,
            role: role,
            userName: userName,
        };

        try {
            const response = await fetch(`${API_BASE_URL}/auth/signup`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });
            
            if (!response.ok) {
                const contentType = response.headers.get("content-type");
                let errorMessage;
    
                if (contentType && contentType.includes("application/json")) {
                    const errorData = await response.json();
                    errorMessage = errorData.message || 'Registration failed.';
                } else {
                    errorMessage = await response.text();
                }
    
                throw new Error(errorMessage || 'Registration failed. Please try again.');
            }
    
            // Save email and role to localStorage, then navigate
            localStorage.setItem('email', email);
            localStorage.setItem('role', role);
    
            // Navigate to user verification page
            navigate('/user-verification');
        } catch (error) {
            console.error('Registration error:', error.message);
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };


    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible);
    };

    return (
        <div className="flex items-center justify-center bg-gray-100 py-10 mt-20">
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
                
                <form onSubmit={handleRegister} className="space-y-6">
                    {error && <p className="text-red-500">{error}</p>}
                    <div className="space-y-1">
                        <label className="block text-sm font-medium text-gray-700">Email</label>
                        <input
                            type="email"
                            className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="space-y-1">
                        <label className="block text-sm font-medium text-gray-700">Username</label>
                        <input
                            type="text"
                            className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="space-y-1">
                        <label className="block text-sm font-medium text-gray-700">Password</label>
                        <div className="relative">
                            <input
                                id="password"
                                type={passwordVisible ? 'text' : 'password'}
                                className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <span
                                className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer"
                                onClick={togglePasswordVisibility}
                            >
                                <FontAwesomeIcon icon={passwordVisible ? faEye : faEyeSlash} />
                            </span>
                        </div>
                        {password.length > 0 && (
                            <p className={`text-sm mt-1 ${password.length >= MIN_PASSWORD_LENGTH ? 'text-green-600' : 'text-red-500'}`}>
                                {password.length >= MIN_PASSWORD_LENGTH ? (
                                    <>
                                        <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />
                                        Password meets the length requirement.
                                    </>
                                ) : (
                                    <>
                                        <FontAwesomeIcon icon={faTimesCircle} className="mr-1" />
                                        Password must be at least {MIN_PASSWORD_LENGTH} characters long.
                                    </>
                                )}
                            </p>
                        )}
                        {passwordLengthError && (
                            <p className="text-red-500 text-sm">{passwordLengthError}</p>
                        )}
                    </div>

                    <div className="space-y-1">
                        <label className="block text-sm font-medium text-gray-700">Confirm Password</label>
                        <div className="relative">
                            <input
                                type={confirmPasswordVisible ? 'text' : 'password'}
                                className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                            <span
                                className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer"
                                onClick={toggleConfirmPasswordVisibility}
                            >
                                <FontAwesomeIcon icon={confirmPasswordVisible ? faEye : faEyeSlash} />
                            </span>
                        </div>
                        {passwordMatchError && (
                            <p className="text-red-500 text-sm">{passwordMatchError}</p>
                        )}
                    </div>

                    <div className="space-y-1">
                        <label className="block text-sm font-medium text-gray-700">Role</label>
                        <select
                            className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            required
                        >
                            <option value="" disabled>Select Role</option>
                            <option value="customer">Customer</option>
                            <option value="vendor">Vendor</option>
                        </select>
                    </div>

                    <button
                        type="submit"
                        className="w-full bg-indigo-600 text-white py-2 rounded-lg hover:bg-indigo-700 transition duration-300"
                        disabled={isLoading}  // Disable button during loading
                    >
                        {isLoading ? 'Registering...' : 'Register'}
                    </button>


                    <div className="text-center mt-4">
                        <p>
                            Already have an account?{' '}
                            <a href="/login" className="text-indigo-600 hover:underline">
                                Login
                            </a>
                        </p>
                    </div>
                </form>
                
            </div>
        </div>
    );
};

export default Register;
