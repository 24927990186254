import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import RestaurantCard from '../Restaurant/RestaurantCard';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from '../../apiConfig';
import { calculateAverageRating } from '../Restaurant/calculateAverageRating';



const Home = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [restaurants, setRestaurants] = useState([]);
  
  useEffect(() => {
    const fetchRestaurants = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/restaurant/allRestaurants`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (response.ok) {
          const data = await response.json();
          // Filter to get only restaurants with status "Approved"
          const approvedRestaurants = data.filter(
            (restaurant) => restaurant.status === 'Approved'
          );

          // Fetch ratings for each restaurant and store them
          const restaurantsWithRatings = await Promise.all(approvedRestaurants.map(async (restaurant) => {
            const rating = await calculateAverageRating(restaurant.id);
            return {
              ...restaurant,
              averageRating: rating,
            };
          }));

          // Filter out restaurants with null or undefined ratings
          const filteredRestaurants = restaurantsWithRatings.filter(
            (restaurant) => restaurant.averageRating !== null && restaurant.averageRating !== undefined
          );

          // Sort restaurants by average rating (highest first)
          filteredRestaurants.sort((a, b) => (b.averageRating || 0) - (a.averageRating || 0));

          setRestaurants(filteredRestaurants.slice(0, 12)); // Display top 12 approved restaurants
        }
      } catch (error) {
        console.error('Error fetching restaurants:', error);
      }
    };

    fetchRestaurants();
  }, []);
  
  useEffect(() => {
    localStorage.removeItem('searchTerm'); // Clear search term when Home page mounts
  }, []);

  const handleSearchClick = () => {
    localStorage.setItem('searchTerm', searchTerm); // Store the new search term in local storage
    navigate('/restaurants'); // Navigate to AllRestaurantsPage
  };

  const handleRestaurantClick = (restaurant) => {
    if (restaurant.openOrClosed === true) {
      navigate(`/restaurant/${restaurant.name}/${restaurant.id}`);
    }
  };

  const cuisines = [
    { title: 'Bengali', image: 'https://cdn.pixabay.com/photo/2022/02/12/15/00/biryani-7009110_640.jpg' },
    { title: 'Pakistani', image: 'https://cdn.pixabay.com/photo/2022/06/10/05/32/biryani-platter-7253751_1280.jpg' },
    { title: 'Italian', image: 'https://cdn.pixabay.com/photo/2024/04/21/18/44/ai-generated-8711272_640.jpg' },
    { title: 'Indian', image: 'https://cdn.pixabay.com/photo/2023/09/28/10/26/indian-restaurant-8281116_640.jpg' },
    { title: 'Mexican', image: 'https://cdn.pixabay.com/photo/2024/06/06/11/53/ai-generated-8812572_640.jpg' },
  ];
  
  const settings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className="w-full min-h-screen bg-gray-50 overflow-x-hidden">
      
      {/* Announcement section */}
      <div className="bg-gray-100 px-4 py-3 text-gray-800 relative flex items-center justify-between mt-[90px]">
        <p className="text-sm font-medium text-center flex-1">
        Are you a Food enthusiast or a Homechef with a love for Cooking? Join us as a
          <a href="/register" className="inline-block underline text-indigo-600 mx-2">Vendor</a> 
          or 
          <a href="/register" className="inline-block underline text-indigo-600 mx-2">Customer</a> 
          on Restoura today!
        </p>
      </div>

      <section
          className="relative bg-[url('https://media.istockphoto.com/id/914940152/photo/empty-wooden-table-top-with-blur-coffee-shop-or-restaurant-interior-background-panoramic-banner.jpg?s=612x612&w=0&k=20&c=mpoF5abC7ys4sTIYUuCemxp3MYFgvuRFVPGzNF8IKTI=')] 
            bg-cover bg-center bg-no-repeat h-[80vh] flex items-center justify-center" 
      >
        <div className="absolute inset-0 bg-black opacity-50"></div> {/* Dark overlay */}
        <div className="relative max-w-screen-xl px-4 pt-2 pb-10 sm:px-6 lg:px-16 text-white text-left">
          <h1 className="text-4xl font-extrabold text-[#FFD700] sm:text-5xl lg:text-6xl leading-tight lg:leading-snug">
            Order Your<br />
            <strong className="font-extrabold text-[#FF6347]">Favorite Homemade Food</strong>
          </h1>
          <p className="mt-4 max-w-lg text-lg sm:text-xl text-yellow-50">
            At Restoura, we bring you the best homemade food from local chefs right to your doorstep.
          </p>
          <div className="mt-12 flex flex-col sm:flex-row gap-4 items-start">
            <input
              type="text"
              placeholder="Search your nearby restaurants"
              className="w-full sm:w-80 rounded-md bg-white px-6 py-4 text-sm font-medium text-gray-700 shadow-md focus:outline-none focus:ring-2 focus:ring-rose-500 transition duration-300"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button
              onClick={handleSearchClick}
              className="w-full sm:w-auto rounded-md bg-rose-600 px-16 py-4 text-sm font-medium text-white shadow-md hover:bg-rose-700 transition duration-300 transform hover:scale-105"
            >
              Search
            </button>
          </div>
        </div>

      </section>

      {/* Top Cuisines Section */}
      <section className="px-10 py-10 bg-gray-100">
        <Slider {...settings}>
          {cuisines.map((cuisine, index) => (
            <div key={index} className="px-4">
              <div className="relative group cursor-pointer transition duration-300 transform hover:scale-105">
                <img src={cuisine.image} alt={cuisine.title} className="rounded-lg shadow-lg h-64 w-full object-cover" />
              </div>
            </div>
          ))}
        </Slider>
      </section>

      {/* Handpicked Restaurants Section */}
      <section className="py-8 px-4 bg-white">
        <div className="flex justify-between items-center mb-10">
          <h2 className="text-3xl font-bold text-gray-800">Top Handpicked Restaurants</h2>
          <div className="flex items-center">
            <a
              href="/restaurants" // Link to the restaurants page
              className="group inline-block rounded-md bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 p-[2px] hover:text-white focus:outline-none focus:ring active:text-opacity-75"
            >
              <span className="block rounded-md bg-white px-4 py-2 text-lg font-medium group-hover:bg-transparent">
                Explore More 
              </span>
            </a>
          </div>


        </div>

        {restaurants.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10">
            {restaurants.map((restaurant, index) => (
              <div
                key={index}
                onClick={() => handleRestaurantClick(restaurant)}
                className={`cursor-pointer ${!restaurant.openOrClosed ? 'pointer-events-none opacity-60' : ''} transition duration-300 transform hover:scale-105`}
              >
                <RestaurantCard
                  restaurant={{
                    id: restaurant.id,
                    name: restaurant.name,
                    openOrClosed: restaurant.openOrClosed,
                    operatingHours: restaurant.operatingHours,
                    cuisineType: restaurant.cuisineType,
                    location: {
                      addressLine1: restaurant.addressLine1,
                      city: restaurant.city,
                      state: restaurant.state,
                    },
                  }}
                />
              </div>
            ))}
          </div>
        ) : (
          <p className="text-center text-lg font-medium text-gray-500">
          "Craving something delicious?
          Order from your favorite restaurant and leave a review to see it featured here."
            <p>Start exploring and share your dining experience!</p>
          </p>
        )}
      </section>



      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Home;




