import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import RestaurantCard from './RestaurantCard';
import API_BASE_URL from '../../apiConfig';

const AllRestaurantsPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState({
    sortBy: '',
    offers: false,
    price: '',
    cuisine: '',
  });
  const [restaurants, setRestaurants] = useState([]);

  const navigate = useNavigate();

  // Fetch the search term from localStorage on initial load
  useEffect(() => {
    const savedSearchTerm = localStorage.getItem('searchTerm');
    if (savedSearchTerm) {
      setSearchTerm(savedSearchTerm);
    }

    // Clear search term when component unmounts or if the user navigates away
    return () => {
      localStorage.removeItem('searchTerm'); // Clear search term when leaving AllRestaurantsPage
    };
  }, []);

  useEffect(() => {
    const fetchRestaurants = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/restaurant/allRestaurants`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          const approvedRestaurants = data.filter((restaurant) => restaurant.status === 'Approved');

          // Check for cuisine data for each restaurant
          const restaurantsWithCuisine = [];
          for (const restaurant of approvedRestaurants) {
            const cuisinesResponse = await fetch(`${API_BASE_URL}/cuisine/allCuisines/${restaurant.id}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            });

            if (cuisinesResponse.ok) {
              const cuisineData = await cuisinesResponse.json();
              if (cuisineData.length > 0) {
                restaurantsWithCuisine.push(restaurant); // Only push restaurants that have cuisine data
              }
            } else {
              console.warn(`No cuisine data for restaurant ID: ${restaurant.id}`);
            }
          }
          setRestaurants(restaurantsWithCuisine); // Update the state with filtered restaurants
        } else {
          console.error('Failed to fetch restaurants');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    // Fetch restaurants and check their cuisine data
    fetchRestaurants();
  }, []);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    localStorage.setItem('searchTerm', value); // Update search term in local storage
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value });
  };

  const handleRestaurantClick = (restaurant) => {
    if (restaurant.openOrClosed === true) {
      navigate(`/restaurant/${restaurant.name}/${restaurant.id}`);
    }
  };

  const filteredRestaurants = restaurants.filter((restaurant) => {
    const searchTermLower = searchTerm.toLowerCase();
  
    // Safely handle missing or undefined properties
    const restaurantName = restaurant.name ? restaurant.name.toLowerCase() : '';
    const restaurantAddress = restaurant.addressLine1 ? restaurant.addressLine1.toLowerCase() : '';
    const restaurantCity = restaurant.city ? restaurant.city.toLowerCase() : '';
    const restaurantState = restaurant.state ? restaurant.state.toLowerCase() : '';
  
    return (
      restaurantName.includes(searchTermLower) ||
      restaurantAddress.includes(searchTermLower) ||
      restaurantCity.includes(searchTermLower) ||
      restaurantState.includes(searchTermLower)
    );
  });
  

  return (
    <div className="w-full mt-20 min-h-screen bg-gray-50 overflow-x-hidden">
      <div className="max-w-screen-xl mx-auto px-4 py-12">
        <div className="flex flex-col md:flex-row gap-8">
          {/* filter option */}
          <div className="w-full md:w-1/4 lg:w-1/5 p-6 max-h-[calc(100vh-4rem)] overflow-y-auto">
            <h2 className="text-xl font-semibold mb-4 text-gray-800">Filters</h2>
            <div className="flex flex-col gap-4">
              <select
                name="sortBy"
                value={filter.sortBy}
                onChange={handleFilterChange}
                className="w-full rounded-lg bg-gray-100 px-4 py-3 text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-green-400"
              >
                <option value="">Sort by</option>
                {/* Add more sort options here */}
              </select>
              <select
                name="price"
                value={filter.price}
                onChange={handleFilterChange}
                className="w-full rounded-lg bg-gray-100 px-4 py-3 text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-green-400"
              >
                <option value="">Price</option>
                {/* Add more price options here */}
              </select>
              <select
                name="cuisine"
                value={filter.cuisine}
                onChange={handleFilterChange}
                className="w-full rounded-lg bg-gray-100 px-4 py-3 text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-400"
              >
                <option value="">Cuisine</option>
                {/* Add cuisine options here */}
              </select>
            </div>
          </div>

          <div className="w-full md:w-3/4 lg:w-4/5">
            <div className="mb-6">
              <input
                type="text"
                placeholder="Search by restaurant's address, dishes, cuisines"
                className="w-full rounded-xl bg-gray-100 px-4 py-3 text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-400"
                value={searchTerm} // Set the search term from state
                onChange={handleSearchChange}
              />
            </div>

            <h2 className="text-2xl font-semibold mb-4 text-gray-800">Our Restaurants</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
              {filteredRestaurants.map((restaurant, index) => (
                <div
                  key={index}
                  className={`rounded-lg overflow-hidden shadow-lg cursor-pointer transform transition-transform duration-300 hover:scale-105 ${
                    restaurant.openOrClosed !== true ? 'pointer-events-none opacity-50' : ''
                  }`}
                  onClick={() => handleRestaurantClick(restaurant)}
                >
                  <RestaurantCard
                    restaurant={{
                      id: restaurant.id,
                      name: restaurant.name,
                      openOrClosed: restaurant.openOrClosed,
                      operatingHours: restaurant.operatingHours,
                      cuisineType: restaurant.cuisineType,
                      location: {
                        addressLine1: restaurant.addressLine1,
                        city: restaurant.city,
                        state: restaurant.state,
                      },
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllRestaurantsPage;
