//  'https://restoura.com:8443' 'http://localhost:8080'

const API_BASE_URL = 'https://restoura.com:8443';

export default API_BASE_URL;






