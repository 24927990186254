import React from 'react';
import { Link } from 'react-router-dom';
import { FaTools, FaUtensils } from 'react-icons/fa'; 

const UnderDevelopment = () => {
  return (
    <div className="min-h-screen mt-20 bg-gradient-to-b from-purple-800 to-pink-600 flex flex-col justify-center items-center text-center px-6 py-12">
      <h1 className="text-7xl font-extrabold text-white mb-6 tracking-widest">
        Restoura
      </h1>

      <div className="flex flex-col items-center bg-white bg-opacity-20 rounded-lg shadow-lg p-8 backdrop-blur-md">
        <FaTools className="text-white text-6xl mb-4" /> {/* Icon for "Under Development" */}
        <p className="text-3xl font-semibold text-white mb-2">
          We're Building Something Special!
        </p>
        <p className="text-lg text-gray-200 max-w-2xl mb-8">
          At <span className="font-bold text-purple-100">Restoura</span>, our goal is to create a home-like fine dining experience. This page is still in development, but we’re working hard to deliver something extraordinary.
        </p>
        
        <FaUtensils className="text-white text-4xl mb-4" /> {/* Icon for "Stay Tuned" */}
        <p className="text-lg text-gray-100 max-w-xl">
          Stay with us to explore the delicious flavors and exquisite dishes that define the essence of Restoura!
        </p>

        <Link
          to="/"
          className="inline-block mt-6 px-10 py-3 bg-white text-purple-700 font-semibold rounded-full shadow-lg hover:bg-gray-100 transition duration-300 ease-in-out"
        >
          Back to Home
        </Link>
      </div>

      <div className="mt-12 text-gray-300">
        <p className="text-sm">
          Thank you for your patience! We can’t wait to share the final experience with you.
        </p>
        <p className="text-sm">
          Restoura – Your home for fine dining.
        </p>
      </div>
    </div>
  );
};

export default UnderDevelopment;
