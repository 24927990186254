import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'
import API_BASE_URL from '../../apiConfig';

const VerifyUser = ({ verifyCode, resendCode }) => {
  const [verificationCode, setVerificationCode] = useState(new Array(6).fill(''));
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(300); // 5 minutes in seconds
  const email = localStorage.getItem('email'); // Autofill from local storage
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Create refs for each input
  const inputRefs = useRef(Array(6).fill().map(() => React.createRef()));

  useEffect(() => {
    // Countdown timer
    if (timeRemaining > 0) {
      const timer = setInterval(() => setTimeRemaining((prev) => prev - 1), 1000);
      return () => clearInterval(timer);
    } else {
      setErrorMessage("Verification code has expired.");
      // Optional: Handle code expiration, e.g., reset code
    }
  }, [timeRemaining]);

  const handleChange = (value, index) => {
    if (/^[0-9]?$/.test(value)) {
      const newCode = [...verificationCode];
      newCode[index] = value;
      setVerificationCode(newCode);

      // Move focus to the next input if the current input is filled
      if (value && index < verificationCode.length - 1) {
        inputRefs.current[index + 1].current.focus();
      }
    }
  };

  //verification
  const handleVerification = async () => {
    const code = verificationCode.join('');
    setLoading(true);

    try {
        const response = await fetch(`${API_BASE_URL}/auth/verify`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, verificationCode: code }),
        });

        if (response.ok) {
            const responseData = await response.json();
            
            // Decode token and store necessary info in local storage
            const decodedToken = jwtDecode(responseData.refreshToken);
            const id = decodedToken.userId;

            localStorage.setItem('token', responseData.refreshToken);
            localStorage.setItem('userId', id);

            setSuccessMessage('Verification successful!');
            setErrorMessage('');

            // Delay to show success message, then navigate to onboarding
            setTimeout(handleOnboardingNavigation, 2000);
        } else {
            const contentType = response.headers.get("content-type");
            let errorMessage;

            if (contentType && contentType.includes("application/json")) {
                const errorData = await response.json();
                errorMessage = errorData.message || 'The verification code does not match.';
            } else {
                errorMessage = await response.text();
            }

            setErrorMessage(errorMessage || 'The verification code does not match.');
            setSuccessMessage('');
        }
    } catch (error) {
        console.error('Verification error:', error.message);
        setErrorMessage('An error occurred during verification.');
        setSuccessMessage('');
    } finally {
        setLoading(false); // End loading
    }
  };

  
  // Resend verification code
  const handleResendCode = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/auth/resend?email=${email}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      });
  
      if (response.ok) {
        setIsCodeSent(true);
        setErrorMessage('');
        setSuccessMessage('Verification code resent to your email.');
      } else {
        const contentType = response.headers.get("content-type");
        let errorMessage;
  
        if (contentType && contentType.includes("application/json")) {
          const errorData = await response.json();
          errorMessage = errorData.message || 'Failed to resend the code. Please try again.';
        } else {
          // If the content type is not JSON, read the response as text
          errorMessage = await response.text();
        }
  
        setErrorMessage(errorMessage || 'Failed to resend the code. Please try again.');
      }
    } catch (error) {
      
      setErrorMessage('An error occurred while resending the code. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleOnboardingNavigation = () => {
    const id = localStorage.getItem('userId');
    const userRole = localStorage.getItem('role');

    if (userRole === 'customer') {
      navigate(`/customer-onboarding/${id}`);
    } else if (userRole === 'vendor') {
      navigate(`/vendor-onboarding/${id}`);
    }
  };

  const isCodeComplete = verificationCode.every((digit) => digit !== '');

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <div className="flex flex-col items-center justify-center bg-gray-100 px-4 sm:px-0 py-12 mt-20">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-md sm:max-w-lg">
        <h2 className="text-2xl font-bold text-center mb-6 text-gray-800">
          Verify Your Email
        </h2>

        {/* Countdown Timer Display */}
        <p className="text-sm text-gray-600 text-center mb-4">
          Code expires in {formatTime(timeRemaining)}
        </p>

        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700">
            Email
          </label>
          <input
            type="email"
            value={email}
            readOnly
            className="mt-1 block w-full p-3 border border-gray-300 rounded-md bg-gray-100 cursor-not-allowed focus:outline-none"
          />
        </div>

        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700">
            Verification Code
          </label>
          <div className="flex justify-center space-x-2 mt-2">
            {verificationCode.map((digit, index) => (
              <input
                key={index}
                type="text"
                maxLength="1"
                value={digit}
                onChange={(e) => handleChange(e.target.value, index)}
                ref={inputRefs.current[index]} // Assign ref to each input
                className="w-12 h-12 text-center text-xl font-semibold border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            ))}
          </div>
        </div>

        {errorMessage && (
          <p className="text-red-500 text-sm text-center mb-4">
            {errorMessage}
          </p>
        )}

        {successMessage && (
          <p className="text-green-500 text-sm text-center mb-4">
            {successMessage}
          </p>
        )}

        <button
          onClick={handleVerification}
          disabled={!isCodeComplete || loading}
          className={`w-full py-3 px-4 font-bold text-white rounded-md transition duration-300 ${
            isCodeComplete && !loading
              ? 'bg-blue-500 hover:bg-blue-600'
              : 'bg-blue-300 cursor-not-allowed'
          }`}
        >
          {loading ? 'Verifying...' : 'Verify'}
        </button>

        <div className="mt-6 text-center">
          <p className="text-sm text-gray-600">
            Didn’t receive the code?{' '}
            <button
              onClick={handleResendCode}
              className="text-blue-500 underline hover:text-blue-700 focus:outline-none"
            >
              Resend Verification Code
            </button>
          </p>

          {isCodeSent && (
            <p className="text-green-500 text-sm mt-2">
              Code resent successfully!
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerifyUser;
