import API_BASE_URL from '../../apiConfig';

export const calculateAverageRating = async (restaurantId) => {
  // const token = localStorage.getItem('token');
  try {
    const response = await fetch(`${API_BASE_URL}/order/getOrdersByRestaurant/${restaurantId}`, {
      method: 'GET',
      headers: {
        // 'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const orders = await response.json();
      const ratings = orders
        .map(order => order.orderRating)
        .filter(rating => rating != null); // Get all valid ratings

      if (ratings.length > 0) {
        const average = ratings.reduce((acc, rating) => acc + rating, 0) / ratings.length;
        return average.toFixed(1); // Round to one decimal
      } else {
        return null; // If no ratings are found
      }
    } else {
      console.error('Failed to fetch orders');
      return null;
    }
  } catch (error) {
    console.error('Error fetching orders:', error);
    return null;
  }
};
