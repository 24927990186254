import React, { useState, useEffect } from 'react';
import ReceiptIcon from '@mui/icons-material/Receipt';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import InfoIcon from '@mui/icons-material/Info';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { ListItemIcon, Badge, Button, IconButton, Drawer, List, ListItem, ListItemText, Menu, MenuItem } from '@mui/material';

import { Link, useNavigate } from 'react-router-dom';

function Navbar() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [userId, setUserId] = useState(null);
  const [cartItemCount, setCartItemCount] = useState(0); 
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
      const storedUserRole = localStorage.getItem('role');
      const storedUserId = localStorage.getItem('userId');
      setUserRole(storedUserRole);
      setUserId(storedUserId);
    }

    const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    setCartItemCount(cartItems.length);
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenDrawer(open);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    setIsLoggedIn(false);
    setUserRole(null);
    setUserId(null);
    handleProfileMenuClose();
    navigate('/login');
  };

  const handleProfileNavigation = () => {
    if (userRole === 'customer') {
      navigate(`/customer-profile/my-profile/${userId}`);
    } else if (userRole === 'vendor') {
      navigate(`/vendor-profile/my-profile/${userId}`);
    }
    handleProfileMenuClose();
  };

  const handleCartNavigation = () => {
    navigate(`/cart/${userId}`);
  };

  return (
    <div className="fixed top-0 left-0 w-full z-50 px-5 py-4 bg-white shadow-lg lg:px-20 flex justify-between items-center">
      {/* Logo and Title */}
      <div className="flex items-center space-x-3 cursor-pointer">
        <Link to="/" className="flex items-center space-x-2">
          <div>
            <h1 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-teal-500 to-cyan-400 tracking-tight">
              Restoura
            </h1>
            <p className="text-sm text-gray-500 pl-0.5">Taste of Home</p>
          </div>
        </Link>
      </div>

      {/* Desktop Menu */}
      <div className="hidden md:flex items-center space-x-6 lg:space-x-12">
        <Link
          to="/about-us"
          className="text-gray-800 hover:bg-gradient-to-r from-[#D4C1FF] to-[#C3B6FF] text-opacity-90 px-3 py-1 rounded-md transition-colors duration-300"
        >
          About
        </Link>
        {userRole !== 'vendor' && (
          <Link
            to="/restaurants"
            className="text-gray-800 hover:bg-gradient-to-r from-[#B9C5FF] to-[#A6B4FF] text-opacity-90 px-3 py-1 rounded-md transition-colors duration-300"
          >
            Restaurants
          </Link>
        )}
        {userRole === 'vendor' && (
          <>
            <Link
              to={`/vendor-restoura/my-restaurants/${userId}`}
              className="text-gray-800 hover:bg-gradient-to-r from-[#8FCBFF] to-[#70B9FF] text-opacity-90 px-3 py-1 rounded-md transition-colors duration-300"
            >
              My Restoura
            </Link>

            <Link
              to={`/vendor-restoura/order-management/${userId}`}
              className="text-gray-800 hover:bg-gradient-to-r from-[#8FCBFF] to-[#70B9FF] text-opacity-90 px-3 py-1 rounded-md transition-colors duration-300 ml-4"
            >
              Orders
            </Link>
          </>
        )}

        {userRole === 'customer' && (
          <Link
            to={`/my-restoura/orders-history/${userId}`}
            className="text-gray-800 hover:bg-gradient-to-r from-[#C5A3FF] to-[#9F7FFF] text-opacity-90 px-3 py-1 rounded-md transition-colors duration-300"
          >
            My Orders
          </Link>
        )}
      </div>

      {/* Actions */}
      <div className="hidden md:flex items-center space-x-3 lg:space-x-6">
        {isLoggedIn ? (
          <>
            {userRole === 'customer' && (
              <IconButton
                onClick={handleCartNavigation}
                className="hover:bg-gradient-to-r from-[#F2F2F5] to-[#E2E2E6] px-2 py-1 rounded transition-colors"
              >
                <Badge badgeContent={cartItemCount} color="info">
                  <ShoppingCartIcon style={{ color: '#3B82F6' }} /> 
                </Badge>
              </IconButton>
            )}
            {/* <IconButton className="hover:bg-gradient-to-r from-[#F2F2F5] to-[#E2E2E6] px-2 py-1 rounded transition-colors">
              <NotificationsIcon style={{ color: '#3B82F6' }} />
            </IconButton> */}
            <IconButton
              onClick={handleProfileMenuOpen}
              className="hover:bg-gradient-to-r from-[#F2F2F5] to-[#E2E2E6] px-2 py-1 rounded transition-colors"
            >
              <AccountCircleIcon style={{ color: '#3B82F6' }} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleProfileMenuClose}
            >
              <MenuItem onClick={handleProfileNavigation}>View Profile</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </>
        ) : (
          <>
            <Button
              variant="outlined"
              className="inline-block rounded border border-indigo-600 bg-indigo-600 px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500"
              onClick={() => navigate('/login')}
            >
              Login
            </Button>
            <Button
              variant="contained"
              className="inline-block rounded border border-indigo-600 px-12 py-3 text-sm font-medium text-indigo-600 hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring active:bg-indigo-500"
              onClick={() => navigate('/register')}
            >
              Signup
            </Button>
          </>
        )}
      </div>

    {/* Mobile Menu */}
    <div className="md:hidden">
      <IconButton
        onClick={toggleDrawer(true)}
        className="hover:bg-gradient-to-r from-[#F2F2F5] to-[#E2E2E6] px-2 py-1 rounded transition-colors"
      >
        <MenuIcon style={{ color: '#3B82F6' }} />
      </IconButton>

      <Drawer anchor="right" open={openDrawer} onClose={toggleDrawer(false)}>
        <div className="w-64 bg-white">
          <div className="flex justify-end px-4 pt-4">
            <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon style={{ color: '#3B82F6' }} />
            </IconButton>
          </div>
          <List className="px-4">
            {isLoggedIn ? (
              <>
                {userRole === 'customer' && (
                  <ListItem
                    button
                    onClick={() => {
                      toggleDrawer(false);
                      handleCartNavigation();
                    }}
                    className="hover:bg-gray-50 rounded-lg transition"
                  >
                    <ListItemIcon className="min-w-[32px]"> {/* Adjusted min-width */}
                      <Badge badgeContent={cartItemCount} color="info">
                        <ShoppingCartIcon style={{ color: '#3B82F6' }} />
                      </Badge>
                    </ListItemIcon>
                    <ListItemText primary="My Cart" className="text-gray-700 font-medium ml-[-20px]" />
                  </ListItem>
                )}

 
                {/* <ListItem className="hover:bg-gray-50 rounded-lg transition">
                  <IconButton>
                    <NotificationsIcon style={{ color: '#3B82F6' }} />
                  </IconButton>
                  <ListItemText primary="Notifications" className="ml-3 text-gray-700 font-medium" />
                </ListItem> */}

                <ListItem
                  button
                  onClick={() => {
                    toggleDrawer(false);
                    handleProfileNavigation();
                  }}
                  className="hover:bg-gray-50 rounded-lg transition"
                >
                  <AccountCircleIcon style={{ color: '#3B82F6' }} />
                  <ListItemText primary="View Profile" className="ml-3 text-gray-700 font-medium" />
                </ListItem>

                <ListItem
                  button
                  onClick={() => {
                    toggleDrawer(false);
                    handleLogout();
                  }}
                  className="hover:bg-gray-50 rounded-lg transition"
                >
                  <ExitToAppIcon style={{ color: '#3B82F6' }} />
                  <ListItemText primary="Logout" className="ml-3 text-gray-700 font-medium" />
                </ListItem>
              </>
            ) : (
              <>
                <ListItem className="flex justify-center">
                  <Button
                    variant="outlined"
                    onClick={() => {
                      toggleDrawer(false);
                      navigate('/login');
                    }}
                    className="border-blue-500 text-blue-500"
                  >
                    Login
                  </Button>
                </ListItem>
                <ListItem className="flex justify-center">
                  <Button
                    variant="contained"
                    onClick={() => {
                      toggleDrawer(false);
                      navigate('/register');
                    }}
                    className="bg-blue-500 text-white"
                  >
                    Signup
                  </Button>
                </ListItem>
              </>
            )}



            {userRole !== 'vendor' && (
              <ListItem button component={Link} to="/restaurants" onClick={toggleDrawer(false)} className="hover:bg-gray-50 rounded-lg transition">
                <RestaurantIcon style={{ color: '#3B82F6' }} />
                <ListItemText primary="Restaurants" className="ml-3 text-gray-700 font-medium" />
              </ListItem>
            )}

            {userRole === 'vendor' && (
              <>
                <ListItem
                  button
                  component={Link}
                  to={`/vendor-restoura/my-restaurants/${userId}`}
                  onClick={toggleDrawer(false)}
                  className="hover:bg-gray-50 rounded-lg transition"
                >
                  <RestaurantMenuIcon style={{ color: '#3B82F6' }} />
                  <ListItemText primary="My Restoura" className="ml-3 text-gray-700 font-medium" />
                </ListItem>

                <ListItem
                  button
                  component={Link}
                  to={`/vendor-restoura/order-management/${userId}`}
                  onClick={toggleDrawer(false)}
                  className="hover:bg-gray-50 rounded-lg transition"
                >
                  <ListAltIcon style={{ color: '#3B82F6' }} />
                  <ListItemText primary="Orders" className="ml-3 text-gray-700 font-medium" />
                </ListItem>
              </>
            )}


            {userRole === 'customer' && (
              <ListItem
                button
                component={Link}
                to={`/my-restoura/orders-history/${userId}`}
                onClick={toggleDrawer(false)}
                className="hover:bg-gray-50 rounded-lg transition"
              >
                <ReceiptIcon style={{ color: '#3B82F6' }} />
                <ListItemText primary="My Orders" className="ml-3 text-gray-700 font-medium" />
              </ListItem>
            )}

            <ListItem button component={Link} to="/about-us" onClick={toggleDrawer(false)} className="hover:bg-gray-50 rounded-lg transition">
              <InfoIcon style={{ color: '#3B82F6' }} />
              <ListItemText primary="About Us" className="ml-3 text-gray-700 font-medium" />
            </ListItem>

          </List>
        </div>
      </Drawer>
    </div>

    </div>
  );
}

export default Navbar;
